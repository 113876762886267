import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { I18n } from 'react-i18nify';

import { getAccountId } from '../selectors/account';
import * as Types from '../actions/smb_account_settings';
import Api from './Api';

import { getSMBSettings } from '../selectors/smb_account_settings';

const update = require('immutability-helper');

const hasOwn = (object, field) =>
  Object.prototype.hasOwnProperty.call(object, field);

export default (
  state = {
    showUpload: false,
    showPreview: false,
    processing: false,
    customLogo: '',
    logo: '',
    logoErrors: [],
    saving: false,
    errors: [],
    fields: {
      updateSetting: 'manual',
      hour: '12',
      minute: '00',
      period: 'AM',
      support_enabled: true,
      remove_agent_enabled: false,
      remove_agent_password: '',
      localDomains: [],
      localDomainSelected: 0,
      cpuMax: '30',
    },
    changes: {},
    periods: [
      {
        value: 'AM',
        label: 'AM',
      },
      {
        value: 'PM',
        label: 'PM',
      },
    ],
    updateOptions: [
      {
        value: 'manual',
        label: I18n.t('components.smbAccountSettings.manually'),
      },
      {
        value: 'reboot',
        label: I18n.t('components.smbAccountSettings.reboot'),
      },
      {
        value: 'time',
        label: I18n.t('components.smbAccountSettings.time'),
      },
    ],
  },
  action
) => {
  switch (action.type) {
    case Types.SMB_ACCOUNT_TOGGLE_UPLOAD:
      return {
        ...state,
        showUpload: action.show,
        showPreview: false,
        logoErrors: [],
      };
    case Types.SMB_ACCOUNT_UPLOAD_LOGO:
      return {
        ...state,
        processing: true,
      };
    case Types.SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        showUpload: false,
        logoErrors: [],
      };
    case Types.SMB_ACCOUNT_CHANGE_LOGO_SUCCESS:
      return {
        ...state,
        processing: false,
        logo: `${state.customLogo}?${Date.now()}`,
      };
    case Types.SMB_ACCOUNT_LOGO_ERROR:
      return {
        ...state,
        logoErrors: action.errors,
      };
    case Types.SMB_ACCOUNT_UPLOAD_LOGO_FAILURE:
      return {
        ...state,
        processing: false,
        logoErrors: ['Failed to upload'],
      };
    case Types.SMB_ACCOUNT_TOGGLE_PREVIEW:
      return {
        ...state,
        showPreview: action.show,
      };
    case Types.SMB_ACCOUNT_GET_INFO:
      return {
        ...state,
        processing: true,
      };
    case Types.SMB_ACCOUNT_GET_INFO_SUCCESS: {
      const {
        logo,
        support_enabled,
        remove_agent_enabled,
        remove_agent_password,
        exclusions,
        cpa_max_cpu,
      } = action;
      const updateTime = action.update_time;
      const [hour24, minute] = updateTime.split(':');

      const period = hour24 < 12 || hour24 === 24 ? 'AM' : 'PM';
      const hour = hour24 % 12 || 12;
      const password =
        remove_agent_password === null ? '' : remove_agent_password;

      const domains = (exclusions && exclusions['domains']) || [];
      const selected = domains.length + 1;

      return {
        ...state,
        processing: false,
        customLogo: logo.custom,
        fields: {
          ...state.fields,
          updateSetting: action.auto_update,
          period,
          support_enabled,
          hour: String(hour),
          minute: String(minute),
          remove_agent_enabled,
          remove_agent_password: String(password),
          localDomains: domains,
          localDomainSelected: selected,
          cpuMax: cpa_max_cpu,
        },
        logo: logo.current ? `${logo.current}?${Date.now()}` : '',
      };
    }
    case Types.SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION: {
      const { options } = action;
      const { fields, changes } = state;
      const merged = {
        ...changes,
        ...options,
      };

      Object.keys(options).forEach(key => {
        if (merged[key] === fields[key]) {
          delete merged[key];
        }
      });

      if (merged['localDomains']) {
        merged['localDomainSelected'] = merged['localDomains'].length;
      }

      return update(state, {
        changes: {
          $set: merged,
        },
        errors: {
          $set: [],
        },
      });
    }
    case Types.SMB_ACCOUNT_RESET:
      return update(state, {
        changes: {
          $set: {},
        },
        errors: {
          $set: [],
        },
      });
    case Types.SMB_ACCOUNT_SAVE:
      return {
        ...state,
        saving: true,
      };
    case Types.SMB_ACCOUNT_SAVE_SUCCESS:
      return update(state, {
        saving: { $set: false },
        fields: {
          $merge: state.changes,
        },
        changes: {
          $set: {},
        },
        errors: {
          $set: [],
        },
      });
    case Types.SMB_ACCOUNT_SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        errors: action.errors || ['Failed to update settings'],
      };
    case Types.SMB_ACCOUNT_GET_INFO_FAILURE:
      return {
        ...state,
        processing: false,
      };
    default:
      return state;
  }
};

function* uploadLogo(action) {
  try {
    const accountId = yield select(getAccountId);
    const result = yield call(Api.accounts.upload, accountId, action);

    yield put(Types.uploadLogoSuccess(result));
    yield delay(2000);
    yield put(Types.changeLogo(result));
  } catch (e) {
    yield put(Types.uploadLogoFailure(e));
  }
}

function* checkLogoChange(action) {
  const state = yield select();
  const { etag } = action;
  const { customLogo } = state.smbAccountSettings;

  try {
    const result = yield call(Api.accounts.logo, `${customLogo}?${Date.now()}`);
    if (etag === result) {
      yield put(Types.changeLogoSuccess());
    } else {
      yield delay(2000);
      yield put(Types.changeLogo(etag));
    }
  } catch (e) {
    yield delay(2000);
    yield put(Types.changeLogo(etag));
  }
}

function* getInfo(action) {
  try {
    const accountId = yield select(getAccountId);
    const result = yield call(Api.accounts.read, accountId);
    yield put(Types.getAccountInfoSuccess(result));
  } catch (e) {
    yield put(Types.getAccountInfoFailure(e));
  }
}

function formatTime(hour, minute, period) {
  const hour24 =
    period === 'PM' && hour !== '12' ? parseInt(hour, 10) + 12 : hour;
  const formattedHour =
    String(hour24).length === 1 ? `0${hour24}` : String(hour24);
  const formattedMinute = minute.length === 1 ? `0${minute}` : minute;

  return `${formattedHour}:${formattedMinute}:00`;
}

const hourRe = /^(?:(?:1[0-2])|(?:[0-9]))$/;
const minuteRe = /^[0-5]?[0-9]$/;
function validateTime(hour, minute, period) {
  if (!(period === 'AM' || period === 'PM')) {
    return false;
  }

  if (!hourRe.test(hour)) {
    return false;
  }

  if (!minuteRe.test(minute)) {
    return false;
  }

  return true;
}

function validateEmptyPassword(password) {
  if (password.length === 0) {
    return true;
  }
  if (password.match(/^\s+$/)) {
    return true;
  }

  return false;
}

function* saveSetttings(action) {
  try {
    const [accountId, smbSettings] = yield all([
      select(getAccountId),
      select(getSMBSettings),
    ]);
    const { changes, fields } = smbSettings;
    const values = {
      ...fields,
      ...changes,
    };
    let updates = {};

    if (
      hasOwn(changes, 'period') ||
      hasOwn(changes, 'hour') ||
      hasOwn(changes, 'minute')
    ) {
      if (!validateTime(values.hour, values.minute, values.period)) {
        yield put(Types.saveSettingsFailure(['Invalid time']));
        return;
      }
      updates.update_time = formatTime(
        values.hour,
        values.minute,
        values.period
      );
    }

    if (hasOwn(changes, 'updateSetting')) {
      updates.auto_update = changes.updateSetting;
    }
    if (hasOwn(changes, 'support_enabled')) {
      updates.support_enabled = changes.support_enabled;
    }
    if (hasOwn(changes, 'remove_agent_enabled')) {
      if (validateEmptyPassword(values.remove_agent_password)) {
        yield put(Types.saveSettingsFailure(['Empty Password']));
        return;
      }
      updates.remove_agent_enabled = changes.remove_agent_enabled;
    }
    if (hasOwn(changes, 'remove_agent_password')) {
      if (validateEmptyPassword(values.remove_agent_password)) {
        yield put(Types.saveSettingsFailure(['Empty Password']));
        return;
      }
      updates.remove_agent_password = changes.remove_agent_password;
    }

    if (hasOwn(changes, 'localDomains')) {
      updates.localDomains = changes.localDomains;
      if (updates.localDomains.length > 30) {
        yield put(
          Types.saveSettingsFailure(['Can only configure up to 30 domains'])
        );
        return;
      }
    }

    if (hasOwn(changes, 'cpuMax')) {
      const cpu = changes.cpuMax;
      if (cpu < 0 || cpu > 100 || Number(cpu) !== parseInt(cpu, 10)) {
        yield put(
          Types.saveSettingsFailure([I18n.t('components.atp.errorCpuRange')])
        );
        return;
      }
      updates.cpa_max_cpu = changes.cpuMax;
    }

    const result = yield call(Api.accounts.update, accountId, updates);
    yield put(Types.saveSettingsSuccess(result));
  } catch (e) {
    yield put(Types.saveSettingsFailure([e.error.request.responseText]));
  }
}

export function* SMBAccountsReducerFlow() {
  yield takeEvery(Types.SMB_ACCOUNT_UPLOAD_LOGO, uploadLogo);
  yield takeEvery(Types.SMB_ACCOUNT_GET_INFO, getInfo);
  yield takeEvery(Types.SMB_ACCOUNT_CHANGE_LOGO, checkLogoChange);
  yield takeEvery(Types.SMB_ACCOUNT_SAVE, saveSetttings);
}
