export const WF_LOGS_INIT_DATA = 'WF_LOGS_INIT_DATA';
export const WF_LOGS_GET_DATA = 'WF_LOGS_GET_DATA';
export const WF_LOGS_GET_SUCCESS = 'WF_LOGS_GET_SUCCESS';
export const WF_LOGS_GET_FAILURE = 'WF_LOGS_GET_FAILURE';
export const WF_LOGS_STORE_LOCATIONS = 'WF_LOGS_STORE_LOCATIONS';
export const WF_LOGS_QUERY_DATA = 'WF_LOGS_QUERY_DATA';
export const WF_LOGS_UPDATE_RANGE = 'WF_LOGS_UPDATE_RANGE';
export const WF_LOGS_UPDATE_SORT = 'WF_LOGS_UPDATE_SORT';
export const WF_LOGS_GET_MORE_LOGS = 'WF_LOGS_GET_MORE_LOGS';
export const WF_LOGS_GET_MORE_SUCCESS = 'WF_LOGS_GET_MORE_SUCCESS';
export const WF_LOGS_CHANGE_PAGE = 'WF_LOGS_CHANGE_PAGE';
export const WF_LOGS_EXPORT_CSV = 'WF_LOGS_EXPORT_CSV';
export const WF_LOGS_EXPORT_CSV_SUCCESS = 'WF_LOGS_EXPORT_CSV_SUCCESS';
export const WF_LOGS_EXPORT_CSV_FAILURE = 'WF_LOGS_EXPORT_CSV_FAILURE';
export const WF_LOGS_TOGGLE_COLUMN = 'WF_LOGS_TOGGLE_COLUMN';
export const WF_LOGS_UPDATE_QUERY = 'WF_LOGS_UPDATE_QUERY';
export const WF_LOGS_UPDATE_FIELD = 'WF_LOGS_UPDATE_FIELD';

export const initData = params => ({
  type: 'WF_LOGS_INIT_DATA',
  params,
});

export const getData = params => ({
  type: 'WF_LOGS_GET_DATA',
  params,
});

export const getSuccess = result => ({
  type: 'WF_LOGS_GET_SUCCESS',
  logs: result.hits.map(log => ({
    ...log['_source'],
    type: log['_index'].match(/^(wca-logs|wsg-logs)/) ? 'wca' : 'dns',
    id: log['_id'],
    sort: log.sort,
  })), // eslint-disable-line dot-notation
  logCount: result.total,
});

export const getFailure = () => ({
  type: 'WF_LOGS_GET_FAILURE',
});

export const storeLocations = locations => ({
  type: 'WF_LOGS_STORE_LOCATIONS',
  locations,
});

export const updateQuery = query => ({
  type: 'WF_LOGS_UPDATE_QUERY',
  params: {
    query,
  },
});

export const updateField = field => ({
  type: 'WF_LOGS_UPDATE_FIELD',
  field,
});

export const queryData = () => ({
  type: 'WF_LOGS_QUERY_DATA',
});

export const updateRange = index => ({
  type: 'WF_LOGS_UPDATE_RANGE',
  index,
});

export const updateSort = sort => ({
  type: 'WF_LOGS_UPDATE_SORT',
  sort,
});

export const getMoreLogs = lastSort => ({
  type: 'WF_LOGS_GET_MORE_LOGS',
  params: {
    search_after: lastSort.join(','),
  },
});

export const getMoreSuccess = result => ({
  type: 'WF_LOGS_GET_MORE_SUCCESS',
  logs: result.hits.map(log => ({
    ...log['_source'],
    type: log['_index'].match(/^wca-logs/) ? 'wca' : 'dns',
    id: log['_id'],
    sort: log.sort,
  })), // eslint-disable-line dot-notation
});

export const changePage = (page, rowsPerPage) => ({
  type: 'WF_LOGS_CHANGE_PAGE',
  page,
  rowsPerPage,
});

export const exportCSV = () => ({
  type: 'WF_LOGS_EXPORT_CSV',
});

export const exportCSVSuccess = () => ({
  type: 'WF_LOGS_EXPORT_CSV_SUCCESS',
});

export const toggleColumn = (column, checked) => ({
  type: 'WF_LOGS_TOGGLE_COLUMN',
  column,
  checked,
});

export const exportCSVFailure = () => ({
  type: 'WF_LOGS_EXPORT_CSV_FAILURE',
});
