import { connect } from 'react-redux';
import NewUserTrial from '../../components/signup/NewUserTrial';
import {
  submitEmail,
  updateEmailAddress,
  toggleLogin,
} from '../../actions/signup';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  form: state.signup.emailForm,
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: event => {
    event.preventDefault();
    Mixpanel.track('Trial / New Account-Sign Up', {});
    dispatch(submitEmail());
  },
  updateField: event => {
    dispatch(updateEmailAddress(event.target.value));
  },
  handleLogin: () => {
    Mixpanel.track('Trial / New Account-Sign In', {});
    dispatch(toggleLogin());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUserTrial);
