import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

class ChipInput extends Component {
  state = {
    focus: false,
  };

  onKeyDown = event => {
    const {
      inputValue,
      selectedIndex,
      onSelectIndex,
      filterInput,
    } = this.props;
    const keyPressed = event.key;

    if (
      keyPressed === 'Enter' ||
      keyPressed === ' ' ||
      (keyPressed === 'Tab' && event.target.value)
    ) {
      event.preventDefault();
      this.updateChips(event, filterInput);
    } else if (keyPressed === 'Backspace') {
      let chips = inputValue;

      if (!event.target.value && chips.length) {
        this.deleteChip(chips, selectedIndex);
      }
    } else if (keyPressed === 'ArrowLeft' && selectedIndex > 0) {
      onSelectIndex(selectedIndex - 1);
    } else if (
      keyPressed === 'ArrowRight' &&
      selectedIndex < inputValue.length
    ) {
      onSelectIndex(selectedIndex + 1);
    }
  };

  updateChips = (event, filterInput) => {
    const { inputValue, onChange } = this.props;
    let value = event.target.value;

    if (!value) return;

    let chip = value.trim().replace(/https?:\/\//, '');

    if (filterInput) {
      chip = filterInput(chip);
    }

    if (chip && inputValue.indexOf(chip) < 0) {
      onChange([...inputValue, ...chip.split(/\s+/g)]);
    }

    event.target.value = '';
  };

  deleteChip = (chip, index) => {
    const { onChange } = this.props;

    if (index === chip.length) {
      onChange(chip.slice(0, chip.length - 1));
    } else if (index >= 0) {
      onChange([
        ...chip.slice(0, index),
        ...chip.slice(index + 1, chip.length),
      ]);
    }
  };

  focusInput = event => {
    let children = event.target.children;

    if (children.length) children[children.length - 1].focus();

    this.setState({ focus: true });
  };

  unfocusInput = event => {
    this.setState({ focus: false });
  };

  render() {
    const {
      classes,
      name,
      inputValue,
      selectedIndex,
      onSelectIndex,
      placeholder,
      icon,
      iconClass,
      error,
    } = this.props;

    const chipIconClass = classNames(classes.icon, iconClass);

    let chips = inputValue.map((chip, index) => {
      let style = classNames(classes.chip);
      if (index === selectedIndex) {
        style = classNames(classes.chip, classes.highlight);
      }

      return (
        <Chip
          key={index}
          label={chip}
          className={style}
          style={{ marginRight: '5px' }}
          onKeyDown={this.onKeyDown}
          onClick={() => {
            onSelectIndex(index);
          }}
        />
      );
    });

    return (
      <Grid
        container
        spacing={16}
        alignItems="flex-end"
        className={classes.wrapper}
      >
        <Grid
          item
          xs={12}
          data-test-id="chip-textinput"
          className={classes.chipInputGrid}
        >
          {icon && <div className={chipIconClass}>{icon}</div>}
          <div
            className={classNames(
              classes.chipInputWrapper,
              error ? classes.error : null
            )}
            onClick={this.focusInput}
            onBlur={this.unfocusInput}
          >
            {!this.state.focus && !inputValue.length && placeholder && (
              <span onFocus={this.focusInput} className={classes.placeholder}>
                {placeholder}
              </span>
            )}
            {chips}
            <input
              type="text"
              className={classes.chipsInput}
              onKeyDown={this.onKeyDown}
              onClick={() => {
                onSelectIndex(inputValue.length);
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          data-test-id="chip-input-error"
          className={classes.errorText}
        >
          {error ? error : null}
        </Grid>
      </Grid>
    );
  }
}

ChipInput.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  inputValue: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectIndex: PropTypes.func.isRequired,
  filterInput: PropTypes.func,
  error: PropTypes.string,
};

const styles = theme => ({
  icon: {
    height: '90px',
    width: '20px',
    backgroundColor: '#95CC66',
    padding: '4px',
  },
  placeholder: {
    fontSize: '14px',
    lineHeight: '20px',
    height: '20px',
    color: 'rgb(0,0,0,0.3)',
  },
  wrapper: {
    paddingBottom: '15px',
  },
  chipInputGrid: {
    padding: '0px !important',
    position: 'relative',
    minHeight: '25px',
    display: 'flex',
  },
  chip: {
    height: '24px',
    fontSize: '12px',
    lineHeight: '24px',
    display: 'inline-block',
    maxWidth: '500px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '2px',
    '& span': {
      display: 'inline-block',
    },
  },
  chipInputWrapper: {
    flexWrap: 'wrap',
    border: '1px solid #A8A8A8',
    borderRadius: '0px 2px 2px 0px',
    padding: '4px 8px',
    marginLeft: '0px',
    minHeight: '88px',
    width: '100%',
    backgroundColor: 'white',
    overflow: 'scroll',
  },
  chipsInput: {
    display: 'inline-block',
    border: 0,
    outline: 'none',
    fontSize: '12px',
    height: '24px',
    padding: 0,
    flexGrow: 1,
  },
  highlight: {
    backgroundColor: 'rgba(0,0,0,0.7) !important',
    color: '#FFFFFF',
  },
  error: {
    borderColor: `${theme.statusColors.failure} !important`,
  },
  errorText: {
    color: '#DD1E31',
    fontSize: '13px',
  },
});

export default withStyles(styles, { name: 'ChipInput' })(ChipInput);
