import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import moment from 'moment-timezone';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import GoodIcon from '@material-ui/icons/CheckCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';

import CudaTableTitle from '../CudaTableTitle';
import TableBody from '../../components/table/TableBody';
import CategoryModal from './CategoryModal';
import ExceptionModal from '../../components/basic_filtering/ExceptionModal';
import NetworkModal from '../../components/basic_filtering/NetworkModal';
import DynamicIPModal from '../../components/basic_filtering/DynamicIPModal';

import Timestamp from '../../containers/Timestamp';

import { Mixpanel } from '../../lib/Mixpanel';

import {
  IP,
  ACTIVITY,
  NAME,
  CAT,
  EXCEPTION,
  IPTYPE,
} from '../../actions/basic_filtering';

const fiveDays = 432000 * 1000; // five days in miliseconds
const tenDays = 864000 * 1000; // ten days in miliseconds

let g_timer = null;

class TableComponent extends Component {
  state = {
    networkOpen: false,
    moreAnchorEl: null,
    moreOpen: false,
    catOpen: false,
    exceptionOpen: false,
    sortDirection: 'asc',
    sortIndex: NAME,
  };

  componentWillUnmount() {
    clearInterval(g_timer);
  }

  toggle(index) {
    let direction = 'desc';

    if (this.state.sortIndex === index && this.state.sortDirection === 'desc') {
      direction = 'asc';
    }

    this.setState({ sortDirection: direction, sortIndex: index });
  }

  handleDownload = n => {
    this.props.showDynamicModal(n.origLocation.locations[0].id);
  };

  handleNetworkClose = event => {
    this.setState({ networkOpen: false });
  };

  handleMoreClick = (event, i) => {
    this.setState({ moreOpen: i, moreAnchorEl: event.currentTarget });
  };

  handleMoreClose = event => {
    this.setState({ moreOpen: false });
  };

  handleMoreEdit = config => {
    const { openLocation } = this.props;
    openLocation(config);
    this.setState({ networkOpen: true });
  };

  handleMoreDelete = config => {
    const { removeLocation } = this.props;
    removeLocation(config);
  };

  handleCatEditClick = (event, config, id) => {
    const { openCategory } = this.props;
    openCategory(config, id);
    this.setState({ catOpen: true });
  };

  handleCatClose = () => {
    this.setState({ catOpen: false });
  };

  handleExceptionEditClick = (event, config, id) => {
    const { openException } = this.props;
    openException(config, id);
    this.setState({ exceptionOpen: true });
  };

  handleExceptionClose = () => {
    this.setState({ exceptionOpen: false });
  };

  _filterRows = () => {
    const { query, policies, timezone } = this.props;

    return policies.filter(p => {
      return (
        (p['addresses'] &&
          p['addresses'].join(', ').match(query.toLowerCase(), 'g')) ||
        (p['lastSeen'] &&
          moment
            .tz(p.lastSeen, timezone)
            .format('YYYY-MM-DD HH:mm:ss')
            .match(query.toLowerCase(), 'g')) ||
        (p['name'] && p['name'].toLowerCase().match(query.toLowerCase(), 'g'))
      );
    });
  };

  _sortRows = () => {
    const sortDirection = this.state.sortDirection;

    const policies = this._filterRows();

    if (this.state.sortIndex === IP) {
      return policies.sort((a, b) => {
        return sortDirection === 'desc'
          ? b['addresses'].join(', ') < a['addresses'].join(', ')
            ? -1
            : 1
          : a['addresses'].join(', ') < b['addresses'].join(', ')
          ? -1
          : 1;
      });
    } else if (this.state.sortIndex === ACTIVITY) {
      return policies.sort((a, b) => {
        return sortDirection === 'desc'
          ? b['lastSeen'] < a['lastSeen']
            ? -1
            : 1
          : a['lastSeen'] < b['lastSeen']
          ? -1
          : 1;
      });
    } else if (this.state.sortIndex === NAME) {
      return policies.sort((a, b) => {
        return sortDirection === 'desc'
          ? b['name'].toLowerCase() < a['name'].toLowerCase()
            ? -1
            : 1
          : a['name'].toLowerCase() < b['name'].toLowerCase()
          ? -1
          : 1;
      });
    } else if (this.state.sortIndex === CAT) {
      return policies.sort((a, b) => {
        return sortDirection === 'desc'
          ? b['categories']['categories'].length <
            a['categories']['categories'].length
            ? -1
            : 1
          : a['categories']['categories'].length <
            b['categories']['categories'].length
          ? -1
          : 1;
      });
    } else if (this.state.sortIndex === EXCEPTION) {
      return policies.sort((a, b) => {
        return sortDirection === 'desc'
          ? b['exceptions']['domains'].length <
            a['exceptions']['domains'].length
            ? -1
            : 1
          : a['exceptions']['domains'].length <
            b['exceptions']['domains'].length
          ? -1
          : 1;
      });
    } else if (this.state.sortIndex === IPTYPE) {
      return policies.sort((a, b) => {
        const ipTypeA = a.origLocation.locations.length
          ? a.origLocation.locations[0].ip_type || 'static'
          : 'static';
        const ipTypeB = b.origLocation.locations.length
          ? b.origLocation.locations[0].ip_type || 'static'
          : 'static';
        return sortDirection === 'desc'
          ? ipTypeB < ipTypeA
            ? -1
            : 1
          : ipTypeA < ipTypeB
          ? -1
          : 1;
      });
    }

    return policies;
  };

  pingLocation = ids => {
    const { checkTraffic, setAllTrafficAsNotSeen } = this.props;
    let counter = 0;

    g_timer = setInterval(() => {
      counter++;
      // Waited for more than 20 minutes
      // 150 of 10 seconds interval
      if (counter >= 150) {
        clearInterval(g_timer);
        setAllTrafficAsNotSeen();
        return;
      }
      checkTraffic(ids);
    }, 10000);
  };

  render() {
    const {
      classes,
      loading,
      editCategory,
      editException,
      editLocation,
      locationConfig,
      highlighted,
    } = this.props;
    const deleteClass = classNames(classes.logOptions, classes.deleteOption);
    const now = Date.now();
    const data = this._sortRows();

    let pingLocationIds = [];
    for (let i in data) {
      if (data[i]['lastSeen'] === null) {
        pingLocationIds.push(data[i]['origAddresses'][0]['id']);
      }
    }

    clearInterval(g_timer);
    if (pingLocationIds.length > 0) {
      this.pingLocation(pingLocationIds);
    }

    return (
      <div>
        <Table data-test-id="basic-filtering-table">
          <TableHead>
            <TableRow>
              <TableCell data-test-id="name">
                <CudaTableTitle
                  value="components.basicFiltering.name"
                  sort
                  toggle={
                    this.state.sortIndex === NAME
                      ? this.state.sortDirection
                      : 'none'
                  }
                  onClick={() => {
                    this.toggle(NAME);
                  }}
                />
              </TableCell>
              <TableCell data-test-id="ipType">
                <CudaTableTitle
                  value="components.basicFiltering.type"
                  sort
                  toggle={
                    this.state.sortIndex === IPTYPE
                      ? this.state.sortDirection
                      : 'none'
                  }
                  onClick={() => {
                    this.toggle(IPTYPE);
                  }}
                />
              </TableCell>
              <TableCell data-test-id="outboundAddress">
                <CudaTableTitle
                  value="components.basicFiltering.outboundAddress"
                  sort
                  toggle={
                    this.state.sortIndex === IP
                      ? this.state.sortDirection
                      : 'none'
                  }
                  onClick={() => {
                    this.toggle(IP);
                  }}
                />
              </TableCell>
              <TableCell data-test-id="activity">
                <CudaTableTitle
                  value="components.basicFiltering.activityLastSeen"
                  sort
                  toggle={
                    this.state.sortIndex === ACTIVITY
                      ? this.state.sortDirection
                      : 'none'
                  }
                  onClick={() => {
                    this.toggle(ACTIVITY);
                  }}
                />
              </TableCell>
              <TableCell data-test-id="categories">
                <CudaTableTitle
                  value="components.basicFiltering.categoryPolicy"
                  sort
                  toggle={
                    this.state.sortIndex === CAT
                      ? this.state.sortDirection
                      : 'none'
                  }
                  onClick={() => {
                    this.toggle(CAT);
                  }}
                />
              </TableCell>
              <TableCell data-test-id="exceptions">
                <CudaTableTitle
                  value="components.basicFiltering.exceptionPolicy"
                  sort
                  toggle={
                    this.state.sortIndex === EXCEPTION
                      ? this.state.sortDirection
                      : 'none'
                  }
                  onClick={() => {
                    this.toggle(EXCEPTION);
                  }}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody
            loading={loading}
            rowCount={data.length}
            colCount={6}
            lid="components.basicFiltering.noResults"
          >
            {data.length > 0
              ? data.map((n, i) => {
                  const ipType = n.origLocation.locations.length
                    ? n.origLocation.locations[0].ip_type
                    : 'static';
                  const highlight =
                    highlighted &&
                    n.origLocation.location_ids.includes(highlighted);

                  return (
                    <TableRow
                      key={'item' + i}
                      data-test-id={`basic-filtering-row-${i}`}
                      className={classNames({
                        [classes.highlighted]: highlight,
                      })}
                    >
                      <TableCell>{n['name']}</TableCell>
                      <TableCell>
                        {ipType === 'dynamic'
                          ? I18n.t('components.basicFiltering.dynamicIP')
                          : I18n.t('components.basicFiltering.staticIP')}
                      </TableCell>
                      <TableCell
                        data-test-id={`basic-filtering-outbound-ip-${i}`}
                      >
                        {n.addresses.length ? (
                          <Tooltip
                            classes={{
                              tooltip: classes.tooltip,
                            }}
                            placement="bottom-start"
                            title={
                              <div data-test-id={`tooltip-addresses-${i}`}>
                                {n.addresses.map(a => {
                                  const address = a.includes('/')
                                    ? a
                                    : `${a}/32`;
                                  const lastSeen =
                                    n.networkStatus[address] || null;

                                  return (
                                    <div
                                      className={classes.lastSeen}
                                      key={a}
                                      data-test-id={`tooltip-addresses-entry-${i}-${a}`}
                                    >
                                      {lastSeen &&
                                        now - lastSeen < fiveDays && (
                                          <GoodIcon
                                            data-test-id={`basic-filtering-tooltip-good-${i}`}
                                            style={{ fill: '#5BA71B' }}
                                          />
                                        )}
                                      {(!lastSeen ||
                                        (lastSeen &&
                                          now - lastSeen > tenDays)) && (
                                        <ErrorIcon
                                          data-test-id={`basic-filtering-tooltip-error-${i}`}
                                          style={{ fill: '#DD1E31' }}
                                        />
                                      )}
                                      {lastSeen &&
                                        now - lastSeen > fiveDays &&
                                        now - lastSeen < tenDays && (
                                          <WarningIcon
                                            data-test-id={`basic-filtering-tooltip-warning-${i}`}
                                            style={{ fill: '#EE8C00' }}
                                          />
                                        )}
                                      {a}
                                    </div>
                                  );
                                })}
                              </div>
                            }
                          >
                            <div className={classes.lastSeen}>
                              {(n.lastSeen === -1 ||
                                now - n.lastSeen > tenDays) && (
                                <ErrorIcon
                                  data-test-id={`basic-filtering-outbound-error-${i}`}
                                  style={{ fill: '#DD1E31' }}
                                />
                              )}
                              {n.lastSeen !== -1 &&
                                now - n.lastSeen < fiveDays && (
                                  <GoodIcon
                                    data-test-id={`basic-filtering-outbound-good-${i}`}
                                    style={{ fill: '#5BA71B' }}
                                  />
                                )}
                              {n.lastSeen !== -1 &&
                                now - n.lastSeen > fiveDays &&
                                now - n.lastSeen < tenDays && (
                                  <WarningIcon
                                    data-test-id={`basic-filtering-outbound-warning-${i}`}
                                    style={{ fill: '#EE8C00' }}
                                  />
                                )}
                              {n.addresses.length ? (
                                <div className={classes.addresses}>
                                  <div>{n.addresses.join(', ')}</div>
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                          </Tooltip>
                        ) : (
                          <div>-</div>
                        )}
                      </TableCell>
                      <TableCell data-test-id={`basic-filtering-lastseen-${i}`}>
                        {n.lastSeen && n.lastSeen === -1 && (
                          <div
                            className={classes.time}
                            data-test-id="basic-filtering-notraffic"
                          >
                            <Translate value="components.basicFiltering.noTraffic" />
                          </div>
                        )}
                        {n.lastSeen && n.lastSeen !== -1 && (
                          <div className={classes.time}>
                            <Timestamp timestamp={n.lastSeen} />
                          </div>
                        )}
                        {!n.lastSeen && (
                          <div
                            className={classes.lastSeen}
                            data-test-id="spinner-lastseen"
                          >
                            <CircularProgress size="20px" />
                            <div className={classes.time}>
                              <Translate value="components.basicFiltering.waiting" />
                            </div>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className={classes.inline}>
                          <Button
                            id={`category-edit${i}`}
                            color="primary"
                            onClick={event => {
                              this.handleCatEditClick(
                                event,
                                n['categories'],
                                n['id']
                              );
                            }}
                          >
                            <Translate
                              value="components.basicFiltering.categoriesLink"
                              cats={n['categories']['categories'].length}
                            />
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.inline}>
                          <Button
                            id={`network-edit${i}`}
                            color="primary"
                            onClick={event => {
                              this.handleExceptionEditClick(
                                event,
                                n['exceptions'],
                                n['id']
                              );
                            }}
                          >
                            <Translate
                              value="components.basicFiltering.exceptionLink"
                              domains={n['exceptions']['domains'].length}
                            />
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          id={`menu-btn${i}`}
                          aria-label="More"
                          aria-owns={null}
                          aria-haspopup="true"
                          onClick={event => {
                            Mixpanel.track('DNS Filtering / Location Menu', {});
                            this.handleMoreClick(event, i);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={this.state.moreAnchorEl}
                          open={this.state.moreOpen === i}
                          onClose={this.handleMoreClose}
                        >
                          <MenuItem
                            id={`edit-menu-btn${i}`}
                            key="edit"
                            selected={false}
                            onClick={() => {
                              Mixpanel.track(
                                'DNS Filtering / Location Menu-Edit',
                                {}
                              );
                              this.handleMoreEdit(n);
                              this.handleMoreClose();
                            }}
                            className={classes.logOptions}
                          >
                            <EditIcon color="inherit" />
                            <Translate value="shared.edit" />
                          </MenuItem>
                          {ipType === 'dynamic' && (
                            <MenuItem
                              id={`download-menu-btn${i}`}
                              key="download"
                              selected={false}
                              onClick={() => {
                                Mixpanel.track(
                                  'DNS Filtering / Location Menu-Download IP Updater',
                                  {}
                                );
                                this.handleDownload(n);
                                this.handleMoreClose();
                              }}
                              className={classes.logOptions}
                            >
                              <GetAppIcon color="inherit" />
                              <Translate value="components.basicFiltering.downloadUpdater" />
                            </MenuItem>
                          )}
                          <MenuItem
                            id={`remove-menu-btn${i}`}
                            key="delete"
                            selected={false}
                            onClick={() => {
                              Mixpanel.track(
                                'DNS Filtering / Location Menu-Delete',
                                {}
                              );
                              this.handleMoreDelete(n);
                              this.handleMoreClose();
                            }}
                            className={deleteClass}
                          >
                            <DeleteIcon color="inherit" />
                            <Translate value="shared.delete" />
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
        <CategoryModal
          title="components.basicFiltering.categoryPolicy"
          showModal={this.state.catOpen}
          buttonAction={() => {
            editCategory();
            this.handleCatClose();
          }}
          closeDialog={() => {
            this.handleCatClose();
          }}
        />
        <ExceptionModal
          title="components.basicFiltering.exceptionPolicy"
          showModal={this.state.exceptionOpen}
          buttonAction={() => {
            editException();
            this.handleExceptionClose();
          }}
          closeDialog={() => {
            this.handleExceptionClose();
          }}
        />
        <NetworkModal
          title="components.basicFiltering.network"
          showModal={this.state.networkOpen}
          buttonAction={() => {
            editLocation();
            this.handleNetworkClose();
          }}
          closeDialog={() => {
            this.handleNetworkClose();
          }}
          hideNetwork={locationConfig.ipType === 'dynamic'}
          disabled={
            locationConfig.ipType === 'dynamic'
              ? false
              : locationConfig.addresses.length <= 0
          }
        />
        <DynamicIPModal
          title="components.basicFiltering.dynamicIPUpdater"
          showModal={this.props.showDynamicIPModal}
          closeDialog={() => {
            this.props.hideDynamicModal();
          }}
          disabled={locationConfig.addresses.length <= 0}
        />
      </div>
    );
  }
}

TableComponent.propTypes = {
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      addresses: PropTypes.array,
      name: PropTypes.string,
      lastSeen: PropTypes.number,
      categories: PropTypes.shape({
        id: PropTypes.string,
        categories: PropTypes.array,
      }),
      exceptions: PropTypes.shape({
        id: PropTypes.string,
        domains: PropTypes.array,
      }),
    })
  ),
  loading: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  openCategory: PropTypes.func.isRequired,
  editCategory: PropTypes.func.isRequired,
  openException: PropTypes.func.isRequired,
  editException: PropTypes.func.isRequired,
  openLocation: PropTypes.func.isRequired,
  editLocation: PropTypes.func.isRequired,
  removeLocation: PropTypes.func.isRequired,
  showDynamicModal: PropTypes.func.isRequired,
  checkTraffic: PropTypes.func.isRequired,
  setAllTrafficAsNotSeen: PropTypes.func.isRequired,
  locationConfig: PropTypes.object.isRequired,
};

const styles = theme => ({
  logOptions: {
    color: '#5F5F5F',
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  deleteOption: {
    color: '#DD1E31',
  },
  statusIcon: {
    height: '16px',
    width: '16px',
    verticalAlign: 'bottom',
    marginRight: '8px',
  },
  disabledIcon: {
    color: '#5F5F5F',
  },
  successIcon: {
    color: '#5BA71B',
  },
  tableTitle: {
    textTransform: 'uppercase',
  },
  timeLabel: {
    paddingLeft: '20px',
  },
  centered: {
    textAlign: 'center',
  },
  threats: {
    color: '#DD1E31',
  },
  button: {
    float: 'right',
    top: '-61px',
    marginRight: '20px',
    marginBottom: '-50px',
  },
  addresses: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  lastSeen: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '8px',
    },
  },
  highlighted: {
    backgroundColor: theme.colors.highlightYellow,
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    padding: '8px 16px',
    color: '#000000',
    opacity: '1 !important',
    '& $lastSeen': {
      '&:last-child': {
        marginBottom: 0,
      },
      '&:first-child': {
        marginTop: 0,
      },
      margin: '8px 0',
    },
  },
  time: {
    marginLeft: '5px',
  },
});

export default withStyles(styles, { name: 'BasicFilterTableStyles' })(
  TableComponent
);
