import { connect } from 'react-redux';
import StartTrial from '../../components/signup/StartTrial';

const mapStateToProps = state => {
  return {
    sso: state.signup.sso && state.signup.accounts.length,
    login: state.signup.login,
    page: state.signup.page,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(StartTrial);
