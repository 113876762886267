import { connect } from 'react-redux';
import ReportDataTable from '../components/reports/DataTable';

import { retrieveAccount } from '../actions/account';

import { runReport } from '../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  selectedReportType: state.reports.reportSelect,
  loading: state.reports.reportProgress,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  runReport: (type, range, rows, index, user) => {
    dispatch(runReport(type, range, rows, index, user));
  },
  retrieveAccount: (email, account, accounts, services, user_id, time_zone) => {
    dispatch(
      retrieveAccount(email, account, accounts, services, user_id, time_zone)
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDataTable);
