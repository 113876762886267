import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';

import PolicyDropdown from './PolicyDropdown';

import AllowExemption from '../../containers/basic_filtering/AllowExemption';
import DenyExemption from '../../containers/basic_filtering/DenyExemption';

class ExceptionPolicy extends Component {
  onChange = (selected, domains) => {
    this.props.setExceptions(domains, selected);
  };

  render() {
    const { classes, customPolicies, selected } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          className={classes.levelContainer}
          data-test-id="exceptionBoxWrapper"
        >
          <PolicyDropdown
            custom={customPolicies}
            label="exceptionPolicy"
            customPolicyKey="domains"
            selected={selected}
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.catContainer}>
          <div className={classes.exceptionHelp}>
            {I18n.t('components.basicFiltering.exceptionHelp')}
          </div>
          <div className={classes.allowDomain}>
            {I18n.t('components.basicFiltering.allowedDomains')}
          </div>
          <div className={classes.exceptionBlock}>
            <AllowExemption
              icon={
                <CheckIcon
                  classes={{ colorPrimary: classes.icon }}
                  color="primary"
                />
              }
            />
          </div>
          <div className={classes.blockDomain}>
            {I18n.t('components.basicFiltering.blockDomains')}
          </div>
          <div className={classes.exceptionBlock}>
            <DenyExemption
              iconClass={classes.iconBg}
              icon={
                <BlockIcon
                  classes={{ colorPrimary: classes.icon }}
                  color="primary"
                />
              }
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

ExceptionPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string,
      action: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.string.isRequired,
  editSelected: PropTypes.string,
  customPolicies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.number),
      domains: PropTypes.arrayOf(
        PropTypes.shape({
          domain: PropTypes.string,
          action: PropTypes.string,
        })
      ),
      id: PropTypes.string,
    })
  ).isRequired,
  setExceptions: PropTypes.func.isRequired,
  updateExceptions: PropTypes.func.isRequired,
};
ExceptionPolicy.defaultProps = {
  editSelected: 'custom',
};

const styles = {
  exceptionBlock: {
    padding: '0px 5px 0px 7px',
  },
  exceptionHelp: {
    color: 'rgba(0,0,0,0.9)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
  },
  allowDomain: {
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  blockDomain: {
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  iconBg: {
    backgroundColor: '#E34050',
  },
  icon: {
    color: '#FFFFFF',
  },
  filterWrapper: {
    marginTop: '16px',
    padding: '10px 16px',
    backgroundColor: '#F7F7F7',
    border: '1px solid #DDDDDD',
  },
  tableWrapper: {
    borderLeft: '1px solid #DDDDDD',
    borderRight: '1px solid #DDDDDD',
    marginBottom: '24px',
  },
  checkedCategory: {
    marginBottom: '24px',
  },
  catContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'ExceptionPolicy' })(ExceptionPolicy);
