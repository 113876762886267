import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepIcon from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';

import { CudaDialogTitle, ActionButton } from '../CudaDialog';

import { Mixpanel } from '../../lib/Mixpanel';

const styles = theme => ({
  cancelBtn: {
    marginRight: '15px',
  },
  stepRoot: {
    '& svg': {
      width: '27px',
      height: '27px',
      color: '#FFFFFF !important',
      '& circle': {
        color: '#0088CF',
        r: '11px',
        stroke: '#FFFFFF',
      },
      '& text': {
        fill: '#FFFFFF',
      },
    },
  },
  labelRoot: {
    color: '#FFFFFF !important',
  },
  stepActive: {
    '& span': {
      borderColor: '#FFFFFF !important',
    },
  },
  active: {
    fill: '#FFFFFF !important',
    '& text': {
      fill: '#0088CF !important',
    },
  },
  root: {
    backgroundColor: '#0088CF',
    padding: '50px 0px 0px 0px',
    width: '100%',
  },
  title: {
    width: '100%',
  },
  errorMessage: {
    fontSize: '15px',
    color: '#333333',
    backgroundColor: theme.statusColors.error,
    textAlign: 'center',
    margin: '10px 15px',
  },
});

class WizardModal extends Component {
  state = {
    active: 0,
    startPage: true,
  };

  reset = last => {
    console.log('called');
    if (!last) {
      if (this.state.active > 2) {
        Mixpanel.track(
          'DNS Filtering / Add Location Dialog / Step 3-Cancel',
          {}
        );
      } else if (this.state.active > 1) {
        Mixpanel.track(
          'DNS Filtering / Add Location Dialog / Step 2-Cancel',
          {}
        );
      } else {
        Mixpanel.track(
          'DNS Filtering / Add Location Dialog / Step 1-Cancel',
          {}
        );
      }
    }
    this.setState({ active: 0, startPage: true });
  };

  goNext = () => {
    const { steps } = this.props;

    if (this.state.startPage) {
      this.setState({ startPage: false });
    } else if (this.state.active < steps.length) {
      if (this.state.active === 1) {
        Mixpanel.track('DNS Filtering / Add Location Dialog / Step 1-Next', {});
      } else {
        Mixpanel.track('DNS Filtering / Add Location Dialog / Step 2-Next', {});
      }
      this.setState({ active: this.state.active + 1 });
    }
  };

  goBack = () => {
    if (this.state.active > 0) {
      if (this.state.active === 2) {
        Mixpanel.track('DNS Filtering / Add Location Dialog / Step 2-Back', {});
      } else {
        Mixpanel.track('DNS Filtering / Add Location Dialog / Step 1-Back', {});
      }
      this.setState({ active: this.state.active - 1 });
    } else {
      Mixpanel.track(
        'DNS Filtering / Add Location Dialog / Getting Started-Cancel Button',
        {}
      );
      this.setState({ startPage: true });
    }
  };

  render() {
    const {
      classes,
      showModal,
      closeDialog,
      buttonAction,
      steps,
      views,
      disableNext,
      startView,
      loading,
      error,
    } = this.props;
    const { startPage } = this.state;

    return (
      <Dialog
        open={showModal}
        maxWidth="md"
        onEscapeKeyDown={closeDialog}
        className={classes.dialog}
      >
        <CudaDialogTitle onClose={closeDialog}>
          {startPage
            ? I18n.t('components.basicFiltering.gettingStarted')
            : I18n.t('components.basicFiltering.create')}
          {!startPage && (
            <Stepper
              activeStep={this.state.active}
              alternativeLabel
              classes={{ root: classes.root }}
            >
              {steps.map((label, i) => {
                let stepClass = classNames(classes.stepRoot);
                if (i < this.state.active) {
                  stepClass = classNames(classes.stepRoot, classes.stepActive);
                }
                return (
                  <Step key={label} classes={{ root: stepClass }}>
                    <StepLabel
                      classes={{ label: classes.labelRoot }}
                      icon={
                        <StepIcon
                          completed={this.state.active > i}
                          active={i === this.state.active}
                          classes={{ active: classes.active }}
                          icon={i + 1}
                        />
                      }
                    >
                      {I18n.t(label)}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </CudaDialogTitle>
        <DialogContent>
          <div style={{ width: '750px' }}>
            {error.message ? (
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <div
                    data-test-id="wizardErrorWrapper"
                    className={classes.errorMessage}
                  >
                    {error.message}
                  </div>
                </Grid>
              </Grid>
            ) : null}
            {startPage ? startView : views[this.state.active]}
          </div>
        </DialogContent>
        <DialogActions>
          {startPage ? (
            <div>
              <ActionButton
                color="secondary"
                id="wizard-cancel"
                disabled={loading}
                onClick={() => {
                  closeDialog();
                  this.reset();
                }}
                className={classes.cancelBtn}
              >
                {I18n.t('shared.cancel')}
              </ActionButton>
              <ActionButton
                color="primary"
                id="wizard-start"
                disabled={loading}
                style={{ background: '#0088CE' }}
                onClick={() => {
                  Mixpanel.track(
                    'DNS Filtering / Add Location Dialog / Getting Started-Start Button',
                    {}
                  );
                  this.goNext();
                }}
              >
                {I18n.t('shared.start')}
              </ActionButton>
            </div>
          ) : (
            <div>
              <ActionButton
                color="secondary"
                id="wizard-cancel"
                disabled={loading}
                onClick={() => {
                  closeDialog();
                  this.reset();
                }}
                className={classes.cancelBtn}
              >
                {I18n.t('shared.cancel')}
              </ActionButton>
              <ActionButton
                color="secondary"
                id="wizard-back"
                disabled={loading}
                onClick={() => {
                  this.goBack();
                }}
              >
                {I18n.t('shared.back')}
              </ActionButton>
              {this.state.active < steps.length - 1 ? (
                <ActionButton
                  color="primary"
                  id="wizard-next"
                  disabled={
                    loading || (disableNext && disableNext[this.state.active])
                      ? disableNext[this.state.active]()
                      : false
                  }
                  style={{ background: '#0088CE' }}
                  onClick={() => {
                    this.goNext();
                  }}
                >
                  {I18n.t('shared.next')}
                </ActionButton>
              ) : null}
              {this.state.active >= steps.length - 1 ? (
                <ActionButton
                  color="primary"
                  id="wizard-add"
                  disabled={loading}
                  style={{ background: '#0088CE' }}
                  onClick={() => {
                    Mixpanel.track(
                      'DNS Filtering / Add Location Dialog / Step 3-Add',
                      {}
                    );
                    buttonAction();
                    this.reset(true);
                  }}
                >
                  {I18n.t('shared.add')}
                </ActionButton>
              ) : null}
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

WizardModal.propTypes = {
  steps: PropTypes.array.isRequired,
  views: PropTypes.arrayOf(PropTypes.node).isRequired,
  disableNext: PropTypes.arrayOf(PropTypes.func),
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  startPage: PropTypes.bool,
};

export default withStyles(styles, { name: 'BasicFilterWizardModalStyles' })(
  WizardModal
);
