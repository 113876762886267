import React from 'react';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CudaLink from '../CudaLink';

const ViewLink = ({ classes, value, to, ...others }) => (
  <span className={classes.linkWrapper} data-test-id="viewLink">
    <CudaLink
      {...others}
      to={to}
      activeClassName={classes.currentLink}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {value}
      <RightArrow />
    </CudaLink>
  </span>
);
ViewLink.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const styles = {
  linkWrapper: {
    marginRight: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

export default withStyles(styles, { name: 'ViewLink' })(ViewLink);
