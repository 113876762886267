import { connect } from 'react-redux';
import { I18n } from 'react-i18nify';
import ChipInput from '../../components/ChipInput';
import {
  selectAllowIndex,
  changeAllowDomains,
} from '../../actions/basic_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = (state, ownProps) => ({
  inputValue: state.basic_filtering.exceptionConfig.allowDomains,
  selectedIndex: state.basic_filtering.exceptionConfig.allowSelectedIndex,
  placeholder: I18n.t('components.basicFiltering.allowPlaceholder'),
  iconClass: ownProps.iconClass,
  icon: ownProps.icon,
});

const mapDispatchToProps = dispatch => ({
  onSelectIndex: index => {
    dispatch(selectAllowIndex(index));
  },
  onChange: value => {
    Mixpanel.track(
      'DNS Filtering / Add Location Dialog / Step 2-Allowed Domains',
      {}
    );
    dispatch(changeAllowDomains(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
