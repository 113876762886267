import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';

import UploadModal from '../containers/smb_account_settings/UploadModal';

import SectionHeader from './SectionHeader';
import { LOGO_PATH } from '../lib/Env';
import Paper from './CudaPaper';
import { SettingsIcon } from './icons';

import GridColumn from './common/GridColumn';
import Dropdown from './common/Dropdown';

import StatusBar from '../containers/smb_account_settings/StatusBar';
import SettingsItem from './settings/SettingsItem';
import Support from './settings/Support';

import PasswordInput from './PasswordInput';

import LocalDomains from '../containers/LocalDomains';

import CpuThrottle from '../containers/smb_account_settings/CpuThrottle';

class SMBAccountSettings extends React.Component {
  componentDidMount = () => {
    this.props.initData();
  };

  render() {
    const {
      classes,
      msp,
      wca,
      showUploadModal,
      processing,
      openUploadModal,
      closeUploadModal,
      uploadLogo,
      logo,
      autoUpdate,
      fields,
      periods,
      passwordShow,
      updatePeriod,
      updateTime,
      updateOptions,
      updateAgentUpdate,
      updateAgentPassword,
      updateSupportStatus,
      toggleSupport,
      toggleRemoveAgent,
      saving,
    } = this.props;

    const currentLogo = logo || LOGO_PATH;

    return (
      <DocumentTitle
        title={I18n.t('components.smbAccountSettings.pageTitle')}
        data-test-id="account-settings-title"
      >
        <React.Fragment>
          <SectionHeader
            icon={<SettingsIcon style={{ color: '#5F5F5F' }} />}
            title={I18n.t('components.smbAccountSettings.title')}
          />
          <Grid
            data-test-id="custom-logo-wrapper"
            spacing={24}
            container
            alignItems="flex-start"
          >
            <Grid item xs={12} md={7}>
              <Paper
                title={I18n.t('components.smbAccountSettings.customBranding')}
                subTitle={I18n.t(
                  'components.smbAccountSettings.customBrandingSub'
                )}
                loading={processing}
                padding
              >
                <Translate
                  className={classes.label}
                  value="components.smbAccountSettings.currentLogo"
                  tag="div"
                />
                <div className={classNames(classes.logoWrapper, classes.row)}>
                  {!processing && (
                    <img data-test-id="logo" src={currentLogo} alt="logo" />
                  )}
                </div>
                <div className={classNames(classes.row, classes.addRow)}>
                  <Translate
                    className={classes.label}
                    value="components.smbAccountSettings.uploadFromFile"
                  />
                  <Button
                    id="select-logo-file"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      openUploadModal();
                    }}
                  >
                    {I18n.t('components.smbAccountSettings.selectFile')}
                  </Button>
                </div>
                <Translate
                  className={classes.subText}
                  value="components.smbAccountSettings.maxHeight"
                  tag="div"
                />
                <Translate
                  className={classes.subText}
                  value="components.smbAccountSettings.maxWidth"
                  tag="div"
                />
              </Paper>
            </Grid>
            <GridColumn xs={12} md={5} spacing={24}>
              {wca ? (
                <Grid item xs={12}>
                  <Paper
                    title={I18n.t('components.smbAccountSettings.updateTitle')}
                    subTitle={I18n.t(
                      'components.smbAccountSettings.updateSubTitle'
                    )}
                    loading={processing}
                    padding
                  >
                    <Translate
                      className={classes.label}
                      value="components.smbAccountSettings.applyUpdates"
                      tag="div"
                    />
                    <Dropdown
                      data-test-id="agent-update-type"
                      value={updateOptions.find(
                        option => option.value === fields.updateSetting
                      )}
                      options={updateOptions}
                      isDisabled={saving}
                      className={classNames(
                        fields.updateSetting === 'time' && classes.updateType
                      )}
                      onChange={updateAgentUpdate}
                    />
                    {fields.updateSetting === 'time' && (
                      <React.Fragment>
                        <Translate
                          className={classes.label}
                          value="components.smbAccountSettings.updateAt"
                          tag="div"
                        />
                        <div className={classes.timeWrapper}>
                          <TextField
                            data-test-id="agent-update-hour"
                            value={fields.hour}
                            name="hour"
                            disabled={saving}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                root: classNames(
                                  classes.input,
                                  classes.shortInput
                                ),
                              },
                            }}
                            onChange={updateTime}
                          />
                          <span className={classes.colon}>:</span>
                          <TextField
                            data-test-id="agent-update-minute"
                            value={fields.minute}
                            name="minute"
                            disabled={saving}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                root: classNames(
                                  classes.input,
                                  classes.shortInput
                                ),
                              },
                            }}
                            onChange={updateTime}
                          />
                          <Dropdown
                            data-test-id="agent-update-period"
                            value={periods.find(
                              option => option.value === fields.period
                            )}
                            options={periods}
                            isDisabled={saving}
                            className={classes.amDropdown}
                            onChange={updatePeriod}
                          />
                          <Translate
                            className={classes.timeText}
                            value="components.smbAccountSettings.localTime"
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </Paper>
                </Grid>
              ) : null}
              {wca ? (
                <Grid item xs={12}>
                  <Paper
                    title={I18n.t(
                      'components.smbAccountSettings.RemoveAgentTitle'
                    )}
                    subTitle={I18n.t(
                      'components.smbAccountSettings.RemoveAgentSub'
                    )}
                    loading={processing}
                    padding
                  >
                    <SettingsItem
                      text={
                        <Translate
                          value="components.smbAccountSettings.RemoveAgent"
                          className={classes.label}
                        />
                      }
                      toggled={fields.remove_agent_enabled}
                      onToggle={toggleRemoveAgent}
                      padding={false}
                      disabled={saving}
                      labels={['components.shared.on', 'components.shared.off']}
                    />
                    <Grid container data-test-id="agent-password-wrapper">
                      <Grid item xs={6}>
                        <Translate
                          className={classes.label}
                          value="components.smbAccountSettings.RemoveAgentPassword"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PasswordInput
                          fullWidth={false}
                          passwordShow={passwordShow}
                          onChange={updateAgentPassword}
                          disabled={!fields.remove_agent_enabled}
                          value={fields.remove_agent_password}
                        />
                      </Grid>
                    </Grid>
                    <CpuThrottle />
                  </Paper>
                </Grid>
              ) : null}
              {wca ? (
                <Grid item xs={12}>
                  <Paper
                    title={I18n.t(
                      'components.smbAccountSettings.DefineAllLocalDomains'
                    )}
                    loading={processing}
                    padding
                  >
                    <div className={classes.localDomainHeader}>
                      {I18n.t('components.smbAccountSettings.localDomains')}
                    </div>
                    <div className={classes.localDomainWrapper}>
                      <LocalDomains />
                    </div>
                  </Paper>
                </Grid>
              ) : null}
              {!msp ? (
                <Grid item xs={12}>
                  <Support
                    processing={processing}
                    support_enabled={fields.support_enabled}
                    toggleSupport={toggleSupport}
                    saving={saving}
                  />
                </Grid>
              ) : null}
            </GridColumn>
          </Grid>
          <UploadModal
            show={showUploadModal}
            closeDialog={closeUploadModal}
            uploadLogo={uploadLogo}
            processing={processing}
          />
          <StatusBar />
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

SMBAccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  openUploadModal: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  initData: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
  passwordShow: PropTypes.func.isRequired,
  updateAgentPassword: PropTypes.func.isRequired,
  toggleRemoveAgent: PropTypes.func.isRequired,
  msp: PropTypes.bool.isRequired,
  wca: PropTypes.bool.isRequired,
};

const styles = theme => ({
  localDomainWrapper: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  localDomainHeader: {
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  subText: {
    color: theme.colors.secondaryText,
    fontSize: '12px',
    lineHeight: '20px',
    marginLeft: '150px',
  },
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
  button: {
    margin: 0,
    padding: '8px 20px',
  },
  row: {
    display: 'flex',
    marginBottom: '16px',
  },
  addRow: {
    alignItems: 'center',
    '& $label': {
      marginBottom: 0,
      flex: '0 0 150px',
    },
  },
  logoWrapper: {
    border: '1px solid #DDDDDD',
    minHeight: '100px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      maxWidth: '240px',
      maxHeight: '240px',
    },
  },
  logoRuleText: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  dropzone: {
    marginBottom: '16px',
  },
  modalRow: {
    marginBottom: '16px',
  },
  updateType: {
    marginBottom: '16px',
  },

  input: {
    border: '1px solid #DDDDDD',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.06)',
    borderRadius: '2px',
    paddingLeft: '6px',
    paddingRight: '6px',
    fontSize: '14px',
    '& > input': {
      lineHeight: '20px',
      padding: '5px ',
    },
  },
  shortInput: {
    width: '30px',
  },

  timeText: {
    fontSize: '14px',
  },
  colon: {
    margin: '0 4px',
    lineHeight: '20px',
  },
  amDropdown: {
    margin: '0 16px',
    width: '75px',
  },
  timeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles, { name: 'SMBAccountSettings' })(
  SMBAccountSettings
);
