import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ReactGA from 'react-ga';
import DeleteIcon from '@material-ui/icons/Delete';

import { I18n, Translate } from 'react-i18nify';
import CudaTableTitle from '../CudaTableTitle';
import {
  ClipboardIcon,
  RestoreIcon,
  CriticalIcon,
  WarningIcon,
} from '../../components/icons';

import ResolveModal from './ResolveModal';

import Timestamp from '../../containers/Timestamp';

class QuarantineTableHeader extends Component {
  render() {
    const { sortHandler, order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <CudaTableTitle
              value="components.quarantine.detected_on"
              toggle={orderBy === 'timestamp' ? order : 'none'}
              sort={true}
              onClick={() => {
                sortHandler('timestamp');
              }}
            />
          </TableCell>
          <TableCell>
            <CudaTableTitle
              value="components.quarantine.device"
              toggle={orderBy === 'device' ? order : 'none'}
              sort={true}
              onClick={() => {
                sortHandler('device');
              }}
            />
          </TableCell>
          <TableCell>
            <CudaTableTitle
              value="shared.username"
              toggle={orderBy === 'user' ? order : 'none'}
              sort={true}
              onClick={() => {
                sortHandler('user');
              }}
            />
          </TableCell>
          <TableCell>
            <CudaTableTitle
              value="components.quarantine.file"
              toggle={orderBy === 'file' ? order : 'none'}
              sort={true}
              onClick={() => {
                sortHandler('file');
              }}
            />
          </TableCell>
          <TableCell>
            <CudaTableTitle
              value="components.quarantine.report"
              sort={false}
              center={true}
            />
          </TableCell>
          <TableCell>
            <CudaTableTitle
              value="components.quarantine.restore"
              sort={false}
              center={true}
            />
          </TableCell>
          <TableCell>
            <CudaTableTitle
              value="components.quarantine.delete"
              sort={false}
              center={true}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}
QuarantineTableHeader.propTypes = {
  selectAllClick: PropTypes.func,
  sortHandler: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

class QuarantineTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 50,
    showModal: false,
    action: 'restore',
    modalTitle: 'restoreFile',
    buttonAction: this.handleRestore,
    file: {},
    selected: [],
  };

  handleReportClick = (cuid, i) => {
    ReactGA.event({
      category: 'Quarantine',
      action: 'View Report',
    });
    this.props.downloadPdf(cuid, i);
    this.setState({ moreOpen: false, moreAnchorEl: null });
  };

  handleSelectAll = (event, checked) => {
    let selected = [];

    if (checked) {
      const start = this.state.page * this.state.rowsPerPage;
      for (
        let i = start;
        i <
        Math.min(start + this.state.rowsPerPage, this.props.quarantined.length);
        ++i
      ) {
        selected.push(i);
      }
    }

    this.props.setSelected(selected);
    this.setState(this.state);
  };

  handleSelect = id => {
    let selected = this.props.selected;
    let index = this.props.selected.indexOf(id);

    if (index === -1) {
      selected.push(id);
    } else {
      selected.splice(index, 1);
    }

    this.props.setSelected(selected);
    this.setState(this.state);
  };

  handleSort = property => {
    const orderBy = property;
    let order = 'desc';

    if (this.props.orderBy === orderBy && this.props.order === 'desc') {
      order = 'asc';
    }

    this.props.setSelected([]);
    this.props.sortData(orderBy, order);
    this.setState({
      order: order,
      orderBy: orderBy,
    });
  };

  changeRowsPerPage = event => {
    this.props.setSelected([]);
    this.setState({ rowsPerPage: event.target.value });
  };

  changePage = (event, page) => {
    this.props.setSelected([]);
    this.setState({
      page: page,
    });
  };

  handleRestore = () => {
    this.props.restoreClick(this.state.file);
    this.closeDialog();
  };

  handleDelete = () => {
    this.props.deleteClick(this.state.file);
    this.closeDialog();
  };

  restoreClick = file => {
    this.setState({
      showModal: true,
      action: 'restore',
      file: {
        file_name: file[this.props.columns.get('file_name')],
        device_name: file[this.props.columns.get('device_name')],
        quarantine_uuid: file[this.props.columns.get('quarantine_uuid')],
        device_id: file[this.props.columns.get('device_id')],
      },
      buttonAction: this.handleRestore,
      modalTitle: 'restoreFile',
    });
  };

  deleteClick = file => {
    this.setState({
      showModal: true,
      action: 'delete',
      file: {
        file_name: file[this.props.columns.get('file_name')],
        device_name: file[this.props.columns.get('device_name')],
        quarantine_uuid: file[this.props.columns.get('quarantine_uuid')],
        device_id: file[this.props.columns.get('device_id')],
      },
      buttonAction: this.handleDelete,
      modalTitle: 'deleteFile',
    });
  };

  closeDialog = () => {
    this.setState({
      showModal: false,
    });
  };

  isSelected = id => this.props.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      quarantined,
      loading,
      filtered,
      query,
      columns,
      downloadCuid,
      selectCuidIndex,
    } = this.props;
    const { page, rowsPerPage } = this.state;
    const NUM_COLUMNS = 8;
    const quarantineTimestampIdx = columns.get('quarantine_timestamp');
    const deviceNameIdx = columns.get('device_name');
    const fileOwnerIdx = columns.get('file_owner');
    const scanResultIdx = columns.get('scan_result');
    const fileNameIdx = columns.get('file_name');
    const filePathIdx = columns.get('file_path');
    const statusIdx = columns.get('status');
    const batdScanCuidIdx = columns.get('batd_scan_cuid');

    let data = query ? filtered : quarantined;

    return (
      <div>
        <Table className={classes.table}>
          <QuarantineTableHeader
            order={this.props.order}
            orderBy={this.props.orderBy}
            rowCount={rowsPerPage}
            sortHandler={this.handleSort}
          />
          <TableBody>
            {!loading && quarantined.length > 0
              ? data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n, i) => {
                    n = Number.isInteger(n) ? quarantined[n] : n;

                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <Timestamp
                            timestamp={n[quarantineTimestampIdx] * 1000}
                            format="MMM DD, YYYY"
                          />
                          <br />
                          <Timestamp
                            timestamp={n[quarantineTimestampIdx] * 1000}
                            format="HH:mm:ss"
                          />
                        </TableCell>
                        <TableCell>{n[deviceNameIdx]}</TableCell>
                        <TableCell>{n[fileOwnerIdx]}</TableCell>
                        <TableCell>
                          <div className={classes.cellWrap}>
                            <span className={classes.icon}>
                              {n[scanResultIdx] === 'malicious' ? (
                                <CriticalIcon large={true} />
                              ) : null}
                              {n[scanResultIdx] === 'suspicious' ? (
                                <Tooltip
                                  title={I18n.t(
                                    'components.quarantine.maliciousTooltip'
                                  )}
                                  placement="top"
                                >
                                  <span>
                                    <WarningIcon large={true} />
                                  </span>
                                </Tooltip>
                              ) : null}
                            </span>
                            <div className={classes.cellText}>
                              <Tooltip
                                title={n[fileNameIdx] || undefined}
                                placement="top-start"
                              >
                                <div className={classes.file}>
                                  {n[fileNameIdx] || undefined}
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={n[filePathIdx].slice(
                                  0,
                                  n[fileNameIdx]
                                    ? -n[fileNameIdx].length
                                    : undefined
                                )}
                                placement="top-start"
                              >
                                <span>
                                  {n[filePathIdx].slice(
                                    0,
                                    n[fileNameIdx]
                                      ? -n[fileNameIdx].length
                                      : undefined
                                  )}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.iconCell}>
                          {downloadCuid === n[batdScanCuidIdx] &&
                          selectCuidIndex == i ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              aria-label="View"
                              aria-owns={null}
                              aria-haspopup="true"
                              onClick={() =>
                                this.handleReportClick(n[batdScanCuidIdx], i)
                              }
                            >
                              <ClipboardIcon />
                            </IconButton>
                          )}
                        </TableCell>
                        {n[statusIdx] !== 'pending_unquarantine' &&
                        n[statusIdx] !== 'pending_remove' ? (
                          <TableCell className={classes.iconCell}>
                            <IconButton
                              aria-label="View"
                              aria-owns={null}
                              aria-haspopup="true"
                              onClick={() => this.restoreClick(n)}
                            >
                              <RestoreIcon />
                            </IconButton>
                          </TableCell>
                        ) : null}
                        {n[statusIdx] !== 'pending_unquarantine' &&
                        n[statusIdx] !== 'pending_remove' ? (
                          <TableCell className={classes.iconCell}>
                            <IconButton
                              aria-label="View"
                              aria-owns={null}
                              aria-haspopup="true"
                              color="inherit"
                              classes={{ colorInherit: classes.delete }}
                              onClick={() => this.deleteClick(n)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        ) : null}
                        {n[statusIdx] === 'pending_unquarantine' ? (
                          <TableCell
                            style={{ color: '#5BA71B' }}
                            colSpan={2}
                            className={classes.statusCell}
                          >
                            <Tooltip
                              title={I18n.t(
                                'components.quarantine.restoreTooltip'
                              )}
                              placement="top"
                            >
                              <span>
                                <Translate value="components.quarantine.restore_pending" />
                              </span>
                            </Tooltip>
                          </TableCell>
                        ) : null}
                        {n[statusIdx] === 'pending_remove' ? (
                          <TableCell
                            style={{ color: '#DD1E31' }}
                            colSpan={2}
                            className={classes.statusCell}
                          >
                            <Tooltip
                              title={I18n.t(
                                'components.quarantine.deleteTooltip'
                              )}
                              placement="top"
                            >
                              <span>
                                <Translate value="components.quarantine.delete_pending" />
                              </span>
                            </Tooltip>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })
              : null}
            {!loading && quarantined.length === 0 ? (
              <TableRow>
                <TableCell className={classes.noResults} colSpan={NUM_COLUMNS}>
                  <Translate value="components.quarantine.noResults" />
                </TableCell>
              </TableRow>
            ) : null}
            {loading ? (
              <TableRow>
                <TableCell className={classes.noResults} colSpan={NUM_COLUMNS}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TablePagination
                colSpan={NUM_COLUMNS}
                count={this.props.count}
                rowsPerPageOptions={[20, 50, 100]}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.changePage}
                onChangeRowsPerPage={this.changeRowsPerPage}
              />
            </TableRow>
          </TableBody>
        </Table>
        <ResolveModal
          showModal={this.state.showModal}
          title={this.state.modalTitle}
          action={this.state.action}
          closeDialog={this.closeDialog}
          buttonAction={this.state.buttonAction}
          file={this.state.file}
        />
      </div>
    );
  }
}
QuarantineTable.propTypes = {
  quarantined: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  selected: PropTypes.array,
  filtered: PropTypes.array.isRequired,
  query: PropTypes.string.isRequired,
  downloadPdf: PropTypes.func.isRequired,
  downloadCuid: PropTypes.string.isRequired,
};

const styles = {
  table: {
    '& td': {
      paddingRight: '12px',
      paddingLeft: '12px',
      maxWidth: '350px',
    },
    '& th': {
      paddingRight: '12px',
      paddingLeft: '12px',
      maxWidth: '350px',
    },
  },
  iconCell: {
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    transform: 'translate(-50%, -50%)',
    height: '25px',
    width: '20px',
    left: 10,
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cellWrap: {
    position: 'relative',
  },
  cellText: {
    display: 'inline-block',
    paddingLeft: '28px',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '15px',
  },
  statusCell: {
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  delete: {
    color: '#DD1E31',
  },
  file: {
    width: '100%',
    overflow: 'hidden',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '15px',
  },
};

export default withStyles(styles, { name: 'QuarantineTableClasses' })(
  QuarantineTable
);
