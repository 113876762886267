import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { LinkingIcon, SerialIcon } from '../icons';
import FormField from './FormField';

const styles = theme => ({
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '300',
    marginBottom: '20px',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '20px',
    marginBottom: '20px',
    fontWeight: '300',
  },
  errorText: {
    visibility: 'hidden',
    marginLeft: '30px',
    marginTop: '2px',
  },
  errorState: {
    visibility: 'visible',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: '10px',
      width: '100%',
    },
  },
  formWrapper: {
    height: '48px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    color: 'rgba(0,0,0,0.7)',
  },
  progressContainer: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  progressText: {
    display: 'inline-block',
    marginLeft: '8px',
    verticalAlign: 'top',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.7)',
  },
  error: {
    textAlign: 'center',
    color: theme.statusColors.failure,
  },
});

class ActivateForm extends React.Component {
  state = {
    errors: {},
  };

  onChangeInput = event => {
    const field = event.target.name;
    const { errors } = this.state;

    if (!event.target.value) {
      this.setState({
        errors: {
          ...errors,
          [field]: 'Required',
        },
      });
    } else {
      this.setState({
        errors: {
          ...errors,
          [field]: '',
        },
      });
    }
  };

  submit = (serial, code) => {
    const { errors } = this.state;
    const { submit } = this.props;

    if (serial && code) {
      submit(serial, code);
    }
    this.setState({
      errors: {
        ...errors,
        serial: !serial ? 'Required' : '',
        code: !code ? 'Required' : '',
      },
    });
  };

  render() {
    const { classes, processing, cancel, errorTxt } = this.props;
    const { errors } = this.state;
    const { submit, onChangeInput } = this;

    return (
      <Grid container justify="center">
        <Grid item className={classes.title}>
          <Translate value="components.activate.formTitle" />
        </Grid>
        <Grid item className={classes.subTitle}>
          <Translate value="components.activate.formSubTitle" />
        </Grid>
        <Grid item container direction="column" spacing={0}>
          <Grid
            item
            container
            alignItems="center"
            justify="center"
            spacing={16}
          >
            <Grid item xs={10} className={classes.formWrapper}>
              <FormField
                id="serial-number"
                inputRef={input => {
                  this.serial = input;
                }}
                field={{
                  value: undefined,
                  error: errors.serial,
                }}
                placeholder="Serial Number"
                onChange={onChangeInput}
                icon=<SerialIcon />
              />
            </Grid>
            <Grid item xs={10} className={classes.formWrapper}>
              <FormField
                id="linking-code"
                inputRef={input => {
                  this.code = input;
                }}
                field={{
                  value: undefined,
                  error: errors.code,
                }}
                placeholder="Linking Code"
                onChange={onChangeInput}
                icon=<LinkingIcon />
              />
            </Grid>
            <Grid item xs={3} className={classes.buttonWrapper}>
              <Button
                variant="raised"
                fullWidth
                color="secondary"
                disabled={processing}
                onClick={() => {
                  cancel();
                }}
              >
                <Translate value="shared.cancel" />
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.buttonWrapper}>
              {processing ? (
                <div className={classes.progressContainer}>
                  <CircularProgress className={classes.progress} size={24} />
                  <Typography type="body1" className={classes.progressText}>
                    Activating
                  </Typography>
                </div>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="raised"
                  color="primary"
                  onClick={() => {
                    submit(this.serial.value, this.code.value);
                  }}
                >
                  <Translate value="header.activate" />
                </Button>
              )}
            </Grid>
            {errorTxt && (
              <Grid item xs={10} className={classes.formWrapper}>
                <div className={classes.error}>{errorTxt}</div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ActivateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  errorTxt: PropTypes.string,
};

export default withStyles(styles, { name: 'ActivateForm' })(ActivateForm);
