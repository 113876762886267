import { connect } from 'react-redux';
import ExpandableRow from '../../components/wf_logs/ExpandableRow';
import { getADUserHash } from '../../selectors/account';

const mapStateToProps = state => ({
  cats: {
    ...state.basic_filtering.catV2Mapping.categories,
    ...state.basic_filtering.catV3Mapping.categories,
  },
  supercats: {
    ...state.basic_filtering.catV2Mapping.supercategories,
    ...state.basic_filtering.catV3Mapping.supercategories,
  },
  locations: state.wfLogs.locations,
  users: getADUserHash(state),
});

export default connect(
  mapStateToProps,
  undefined
)(ExpandableRow);
