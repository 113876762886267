import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScheduledTable from '../../components/reports/ScheduledTable';
import { getFilteredTypes, getUserOptions } from '../../selectors/reports';

import {
  openEditReport,
  setEditData,
  deleteReport,
  handleSelectReport,
  toggleAllSelectReport,
  runMultiReports,
} from '../../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  reports: state.reports.reports,
  lastSeen: state.reports.lastSeen,
  loading: state.reports.loading,
  timezone: state.account.time_zone,
  reportQueue: state.reports.runReportQueue,
  lastSeenPendingList: state.reports.lastSeenPendingList,
  reportOptions: getFilteredTypes(state),
  userOptions: getUserOptions(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  clickEdit: options => {
    dispatch(openEditReport(options));
  },
  setEditData: (data, options, userOptions) => {
    dispatch(setEditData(data, options, userOptions));
  },
  deleteReport: () => {
    dispatch(deleteReport());
  },
  handleSelectReport: report => {
    dispatch(handleSelectReport(report));
  },
  runMultiReports: () => {
    dispatch(runMultiReports());
  },
  toggleAllSelectReport: reports => {
    dispatch(toggleAllSelectReport(reports));
  },
});

class ScheduledTableWrapper extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event, data) => {
    const { setEditData, reportOptions, userOptions } = this.props;
    this.setState({ anchorEl: event.currentTarget });
    setEditData(data, reportOptions, userOptions);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    return (
      <ScheduledTable
        {...this.props}
        handleClick={this.handleClick}
        anchorEl={this.state.anchorEl}
        handleClose={this.handleClose}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledTableWrapper);
