import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ACCOUNT, ACCOUNT_NAME, EPS_AUTHDB_SERVICE_ID } from '../lib/Env';
import * as Types from '../actions/account';
import WebStorage from '../lib/WebStorage';
import Api from './Api';
import Auth from '../lib/Auth';
import * as AppTypes from '../actions/app';
import { I18n } from 'react-i18nify';

import { showSessionModal } from '../actions/account';
import { getAccountId } from '../selectors/account';

const subscriptions = ['atp', 'dns', 'wca'];

const defaultState = {
  email: '',
  id: null,
  selected: null,
  active: '',
  msp: false,
  time_zone: 'America/Los_Angeles',
  saving: false,
  dashboardInfo: {},
  dashboardLoading: false,
  totalAgents: 0,
  activeAgents: 0,
  serials: {},
  init: false,
  trial: false,
  accounts: [],
  supportAccess: [],
  cpuMax: '30',
  supportAccessEnabled: true,
  supportAccessLoading: true,
  supportAccessSaving: false,
  fieldsUpdated: [],
  subscriptions: subscriptions.reduce(
    (obj, sub) => ({ ...obj, [sub]: false }),
    {}
  ),
  mspSubs: 0,
  accountSubs: {},
  showResult: false,
  updateResult: 'success',
  loading: false,
  userName: '',
  installers: [],
  latestInstallers: [],
  sessionToken: {
    show: false,
    processing: false,
  },
  select_retrieve_account: null,
  mspSubStats: [],
  loadingActiveDirectory: false,
  activeDirectory: [],
  adMap: {},
  downloadingAgentConfig: false,
};

export const account = (
  state = {
    ...defaultState,
  },
  action
) => {
  switch (action.type) {
    case Types.DELETE_SESSION:
      return {
        ...defaultState,
      };
    case Types.STORE_USER_NAME:
      return {
        ...state,
        userName: action.userName,
      };
    case Types.ACCOUNT_DOWNLOAD_CONFIG:
      return {
        ...state,
        downloadingAgentConfig: true,
      };
    case Types.ACCOUNT_DOWNLOAD_CONFIG_SUCCESS:
    case Types.ACCOUNT_DOWNLOAD_CONFIG_FAILURE:
      return {
        ...state,
        downloadingAgentConfig: false,
      };
    case Types.ACCOUNT_DASHBOARD_SUCCESS:
      return {
        ...state,
        mspSubStats: action.mspSubStats,
        dashboardInfo: action.result.accounts,
        totalAgents: action.result.agent_info.total,
        activeAgents: action.result.agent_info.active,
        dashboardLoading: false,
        init: true,
      };
    case Types.ACCOUNT_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboardLoading: false,
      };
    case Types.ACCOUNT_DASHBOARD_LOADING:
      return {
        ...state,
        dashboardLoading: true,
        supportAccessLoading: true,
        init: false,
      };
    case Types.GET_ACCOUNT_INSTALLER_INFO:
      return {
        ...state,
        select_retrieve_account: action.account,
      };
    case Types.ACCOUNT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.ACCOUNT_TOGGLE_SUPPORT_ACCESS:
      return {
        ...state,
        supportAccess: !state.supportAccess,
        supportAccessSaving: true,
      };
    case Types.ACCOUNT_TOGGLE_SUPPORT_ACCESS_SUCCESS:
      return {
        ...state,
        supportAccessSaving: false,
        supportAccess: action.supportAccess,
      };
    case Types.ACCOUNT_TOGGLE_SUPPORT_ACCESS_FAILED:
      return {
        ...state,
        supportAccessSaving: false,
        supportAccessEnabled: !state.supportAccessEnabled,
      };
    case Types.RETRIEVE_ACCOUNT: {
      let selected = null;
      let name = null;
      let msp = false;
      let serial = '';
      let trial = false;

      // TODO: Move this into the /token endpoint to return MSP data
      // when Account Service is available to give list of accounts
      // MSP dashboard data will return all the account information
      // and /token will return whether account is MSP or SMB
      // for UI to determine which route to display.
      let services = action.services
        .filter(s => {
          return Number(s.service_id) === EPS_AUTHDB_SERVICE_ID;
        })
        .map(s => {
          return Number(s.account_id);
        });

      let accountList = action.accounts.filter(a => {
        return services.includes(Number(a.id));
      });

      let accountNames = new Map();
      action.accounts.forEach(function(a) {
        accountNames.set(Number(a.id), a.account_name);
      });

      const re = /Partner\s*$/g;
      msp = action.accounts.some(a => a.type && a.type.match(re));
      if (services.length > 1) {
        msp = true;
      }

      // Select the account with the entitlements
      if (!msp) {
        selected = Number(services[0]);
        name = accountNames.get(Number(services[0]));
        serial = (state.serials || {})[selected] || '';
        trial = serial.indexOf('TRIAL') === 0;
      }

      return {
        ...state,
        id: action.id,
        email: action.email,
        selected: selected,
        name: name,
        msp: msp,
        serial,
        trial,
        user_id: parseInt(action.user_id, 10),
        time_zone: action.time_zone,
        accountNames: accountNames,
        accounts: accountList,
        services,
      };
    }
    case Types.ACCOUNT_CHANGE:
      return {
        ...state,
        active: action.account_name,
      };
    case Types.SELECT_ACCOUNT:
      return {
        ...state,
        selected: action.id,
        name: action.name,
        serial: state.serials
          ? state.serials[parseInt(action.id, 10)] || ''
          : '',
      };
    case Types.UNSELECT_ACCOUNT:
      return {
        ...state,
        selected: null,
        serial: null,
        name: null,
        activeDirectory: [],
        adMap: {},
      };
    case Types.CHANGE_PASSWORD:
    case Types.CHANGE_TIMEZONE:
      return {
        ...state,
        fieldsUpdated: [action.field],
        saving: true,
      };
    case Types.ACCOUNT_UPDATE_FAILURE:
      return {
        ...state,
        saving: false,
        updateResult: 'failure',
        showResult: true,
      };
    case Types.ACCOUNT_REMOVE_STATUS_BAR:
      return {
        ...state,
        showResult: false,
      };
    case Types.ACCOUNT_UPDATE_TZ_SUCCESS:
      return {
        ...state,
        time_zone: action.time_zone,
      };
    case Types.ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        saving: false,
        updateResult: 'success',
        fieldsUpdated: [action.field],
        showResult: true,
      };
    case Types.ACCOUNT_UPDATE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: {
          ...subscriptions.reduce((obj, sub) => ({ ...obj, [sub]: false }), {}),
          ...action.subs.reduce((obj, sub) => ({ ...obj, [sub]: true }), {}),
        },
      };
    case Types.ACCOUNT_SAVE_SUBSCRIPTIONS:
      return {
        ...state,
        mspSubs: action.mspSubs,
        accountSubs: action.accountSubs,
        serials: action.serials,
      };
    case Types.STORE_SERIALS:
      return {
        ...state,
        serials: action.serials,
      };
    case Types.ACTIVE_DIRECTORY_LOADING:
      return {
        ...state,
        loadingActiveDirectory: true,
      };
    case Types.GET_ACTIVE_DIRECTORY_SUCCESS:
      return {
        ...state,
        activeDirectory: action.flatAD,
        adMap: action.adMap,
        loadingActiveDirectory: false,
      };
    case Types.GET_ACCOUNTS_SUPPORT_ACCESS_SUCCESS:
      return {
        ...state,
        supportAccess: action.supportAccess,
        supportAccessEnabled: !action.supportAccess.some(support => {
          return !support['support_enabled'];
        }),
        supportAccessLoading: false,
      };
    case Types.MSP_TOGGLE_SUPPORT_TUNNEL:
      return {
        ...state,
        supportAccessEnabled: action.supportEnabled,
        supportAccessSaving: true,
      };
    case Types.MSP_TOGGLE_SUPPORT_SUCCESS:
      return {
        ...state,
        supportAccess: action.result,
        supportAccessEnabled: !action.result.some(support => {
          return !support['support_enabled'];
        }),
        supportAccessLoading: false,
        supportAccessSaving: false,
      };
    case Types.MSP_TOGGLE_SUPPORT_FAILED:
      return {
        ...state,
        supportAccessEnabled: !state.supportAccessEnabled,
        supportAccessLoading: false,
        supportAccessSaving: false,
      };
    case Types.ACCOUNT_GET_INSTALLERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.ACCOUNT_GET_INSTALLERS_SUCCESS: {
      const { installers } = action;
      let current = [];
      let other = [];

      Object.keys(installers).forEach(key => {
        if (['windows', 'Macintosh'].indexOf(key) !== -1) {
          current.push(installers[key][0]);
          other = [...other, ...installers[key].slice(1)];
        }
      });

      return {
        ...state,
        installers: other,
        latestInstallers: current,
        loading: false,
      };
    }
    case Types.ACCOUNT_GET_INSTALLERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.SHOW_SESSION_MODAL:
      return {
        ...state,
        sessionToken: {
          ...state.sessionToken,
          show: true,
          processing: false,
        },
      };
    case Types.REFRESH_SESSION:
      return {
        ...state,
        sessionToken: {
          ...state.sessionToken,
          processing: true,
        },
      };
    case Types.SUCCESS_REFRESH_TOKEN:
    case Types.FAILED_REFRESH_TOKEN:
    case Types.CLOSE_SESSION_MODAL:
      return {
        ...state,
        sessionToken: {
          ...state.sessionToken,
          show: false,
          processing: false,
        },
      };
    default:
      return state;
  }
};

function* fetchAccountInfo() {
  try {
    const store = yield select();
    yield put(Types.accountLoading());
    const result = yield call(Api.getData, {
      page: 'account_installers',
      account: store.account.select_retrieve_account,
    });
    yield put(Types.getAccountInfoSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getAccountInfoFailure(e.error));
  }
}

function* retrieveAccounts() {
  const store = yield select();
  const account = WebStorage.get(ACCOUNT);
  const name = WebStorage.get(ACCOUNT_NAME);
  if (store.account.msp && account) {
    yield put(Types.selectAccount(account, name));
  }
}

function* selectAccounts() {
  const store = yield select();
  yield WebStorage.store(ACCOUNT, store.account.selected);
  yield WebStorage.store(ACCOUNT_NAME, store.account.name);
  yield put(
    Types.updateSubscriptions(
      store.account.accountSubs[parseInt(store.account.selected, 10)]
    )
  );
}

function* unselectAccounts() {
  const store = yield select();
  yield WebStorage.remove(ACCOUNT);
  yield WebStorage.store(ACCOUNT_NAME);
  yield put(Types.updateSubscriptions(store.account.mspSubs));
}

function* changePassword(action) {
  try {
    const store = yield select();
    const result = yield call(Api.users.update, store.account.user_id, {
      password: action.value,
    });
    yield put(Types.accountUpdateSuccess('password'));
  } catch (e) {
    yield put(Types.accountUpdateFailure(e));
  }
  yield delay(5000);
  yield put(Types.removeStatusBar());
}

function* changeTimezone(action) {
  try {
    const store = yield select();
    const result = yield call(Api.users.update, store.account.user_id, {
      timezone: action.value,
    });
    yield put(Types.accountUpdateSuccess('timezone'));
    yield put(Types.accountUpdateTZSuccess(action.value));
    Auth.refresh_token();
  } catch (e) {
    yield put(Types.accountUpdateFailure(e));
  }
  yield delay(5000);
  yield put(Types.removeStatusBar());
}

function* deleteSession(action) {
  try {
    // Activation may not have jwe when logging out
    if (Auth.get_token()) {
      yield call(Api.session.delete, action.token);
    }
  } catch (e) {}
}

function* initAccountDashboard() {
  try {
    const store = yield select();
    yield put(Types.accountDashboardLoading());
    const result = yield call(Api.getData, {
      page: 'account_dashboard',
      range: 'month',
      fileTypeTime: store.mspDashboard.fileTypeRange,
    });
    yield put(Types.getAccountDataSuccess(result));

    // Get support account information
    let accounts = [];
    try {
      accounts = yield call(Api.accounts.getAll);
    } catch (e) {
      // Silently fail so we don't crash the UI
    }
    yield put(Types.getSupportAccessSuccess(accounts));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getAccountDataFailure(e.error));
  }
}

function* mspToggleSupport(action) {
  try {
    const store = yield select();
    const result = yield call(Api.accounts.updateAll, {
      support_enabled: store.account.supportAccessEnabled,
    });
    yield put(Types.toggleMSPSupportSuccess(result));
  } catch (e) {
    const { error } = e;
    yield put(Types.toggleMSPSupportFailed(error));
    if (error.response) {
      if (error.response.status == 500) {
        return yield put(AppTypes.error(I18n.t('errors.500')));
      }
      yield put(AppTypes.error(error.response.data));
    } else if (error.request) {
      yield put(AppTypes.error(I18n.t('errors.503')));
    } else {
      yield put(AppTypes.error(I18n.t('errors.500')));
    }
  }
}
function* toggleSupport(action) {
  try {
    const store = yield select();
    const enabled =
      store.account.supportAccess[action.account_id]['support_enabled'];
    const result = yield call(Api.accounts.update, action.account_id, {
      support_enabled: enabled,
    });
    yield put(Types.toggleSupportSuccess(action.account_id));
  } catch (e) {
    const { error } = e;
    yield put(Types.toggleSupportFailed(action.account_id));
    if (error.response) {
      if (error.response.status == 500) {
        return yield put(AppTypes.error(I18n.t('errors.500')));
      }
      yield put(AppTypes.error(error.response.data));
    } else if (error.request) {
      yield put(AppTypes.error(I18n.t('errors.503')));
    } else {
      yield put(AppTypes.error(I18n.t('errors.500')));
    }
  }
}

function* getActiveDirectory() {
  try {
    const store = yield select();
    yield put(Types.activeDirectoryLoading());
    const result = yield call(Api.directory.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getActiveDirectorySuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getActiveDirectoryFailure(e.error));
  }
}

function* getSubscription(action) {
  try {
    const store = yield select();
    if (action.useCache && store.account.mspSubStats.length > 0) {
      return;
    }

    yield put(Types.accountDashboardLoading());
    const result = yield call(Api.getData, {
      page: 'account_dashboard',
      range: 'month',
      fileTypeTime: store.mspDashboard.fileTypeRange,
    });
    yield put(Types.getAccountDataSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getAccountDataFailure(e.error));
  }
}

function* downloadAccountConfig() {
  try {
    const store = yield select();
    const params = {
      account_id: store.account.selected,
    };

    yield call(Api.downloadAccountConfig, params);
    yield put(Types.downloadAccountConfigSuccess());
  } catch (e) {
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
    yield put(Types.downloadAccountConfigFailure(e));
  }
}

function* getInstallers() {
  try {
    const accountId = yield select(getAccountId);
    const installers = yield call(Api.accounts.installers, accountId);
    yield put({
      type: Types.ACCOUNT_GET_INSTALLERS_SUCCESS,
      installers,
    });
  } catch (e) {
    yield put({
      type: Types.ACCOUNT_GET_INSTALLERS_FAILURE,
    });
  }
}

function* refreshSession(action) {
  try {
    const token = Auth.get_refresh_token();
    const result = yield call(Api.auth.refresh, token);
    Auth.setup_sso(result['sso']['id_token'], result['sso']['refresh_token']);
    Auth.store_token(result['jwe']);
    yield put(Types.successRefresh());
    yield put(AppTypes.success(I18n.t('shared.refreshSuccess')));
  } catch (e) {
    yield put(Types.failedRefresh());
    yield put(AppTypes.error(I18n.t('errors.failedRefresh')));
  }
}

export function* accountsReducerFlow() {
  yield takeEvery(Types.INIT_ACCOUNT_DASHBOARD, initAccountDashboard);
  yield takeEvery(Types.RETRIEVE_ACCOUNT, retrieveAccounts);
  yield takeEvery(Types.SELECT_ACCOUNT, selectAccounts);
  yield takeEvery(Types.UNSELECT_ACCOUNT, unselectAccounts);
  yield takeEvery(Types.CHANGE_PASSWORD, changePassword);
  yield takeEvery(Types.CHANGE_TIMEZONE, changeTimezone);
  yield takeEvery(Types.GET_ACCOUNT_INSTALLER_INFO, fetchAccountInfo);
  yield takeEvery(Types.DELETE_SESSION, deleteSession);
  yield takeEvery(Types.GET_ACTIVE_DIRECTORY, getActiveDirectory);
  yield takeEvery(Types.GET_ACCOUNT_SUBSCRIPTION, getSubscription);
  yield takeEvery(Types.ACCOUNT_DOWNLOAD_CONFIG, downloadAccountConfig);
  yield takeEvery(Types.ACCOUNT_TOGGLE_SUPPORT_ACCESS, toggleSupport);
  yield takeEvery(Types.MSP_TOGGLE_SUPPORT_TUNNEL, mspToggleSupport);
  yield takeEvery(Types.ACCOUNT_GET_INSTALLERS, getInstallers);
  yield takeEvery(Types.REFRESH_SESSION, refreshSession);
}
