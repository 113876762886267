import { createSelector } from 'reselect';

const getGroups = state => state.userAuthentication.groups;
const getUsers = state => state.userAuthentication.users;
const getQuery = state => state.userAuthentication.query;
const getSort = state => state.userAuthentication.sort;
const getModal = state => state.userAuthentication.modal;
const getUserAuthentication = state => state.userAuthentication;

const getFilteredGroups = createSelector(
  [getGroups, getUsers, getQuery, getSort],
  (groups, users, rawQuery, sort) => {
    const query = rawQuery.toLowerCase();

    return Object.keys(groups)
      .map(key => ({
        ...groups[key],
        users: groups[key].users
          .map(userID => users[userID])
          .filter(
            user =>
              (user.name && user.name.toLowerCase().indexOf(query) !== -1) ||
              (user.email && user.email.toLowerCase().indexOf(query) !== -1)
          )
          .sort((a, b) => {
            return sort.direction === 'desc'
              ? a[sort.field].localeCompare(b[sort.field])
              : b[sort.field].localeCompare(a[sort.field]);
          }),
      }))
      .filter(group => !query || group.users.length)
      .sort((a, b) => {
        return b.name === 'Users' ? -1 : a.name.localeCompare(b.name);
      });
  }
);

const getGroupList = createSelector(
  [getGroups],
  groups => {
    let groupList = Object.keys(groups).map(key => ({
      value: groups[key].id,
      label: groups[key].name,
    }));

    if (!groupList.find(e => e.label === 'Users')) {
      groupList.push({
        value: 'Users',
        label: 'Users',
        __isNew__: true,
      });
    }

    return groupList.sort((a, b) => a.label.localeCompare(b.label));
  }
);

const modalChanged = createSelector(
  [getModal],
  modal => Boolean(Object.keys(modal.changes).length)
);

const getModalData = createSelector(
  [getModal],
  modal => ({
    ...modal.data,
    ...modal.changes,
  })
);

export {
  getFilteredGroups,
  getGroupList,
  modalChanged,
  getUserAuthentication,
  getModalData,
};
