import { connect } from 'react-redux';
import Header from '../../components/reports/Header';
import { I18n } from 'react-i18nify';
import { getUserOptions } from '../../selectors/reports';

const mapStateToProps = (state, ownProps) => {
  const accounts = state.account.accounts;
  const selected = state.account.selected;

  let name = '';
  for (var i = 0; i < accounts.length; i++) {
    if (parseInt(accounts[i].id, 10) === parseInt(selected, 10)) {
      name = accounts[i].name;
      break;
    }
  }

  let translate = 'shared.reports.' + state.reports.genReport;
  let username = '';
  if (state.reports.genReport === 'per-user-reports') {
    translate = 'shared.reports.' + state.reports.genReport + '-title';
    const users = getUserOptions(state);
    for (var i = 0; i < users.length; i++) {
      if (users[i]['key'] === state.reports.userSelect) {
        username = users[i]['label'];
        break;
      }
    }
  }
  const title = I18n.t(translate, { name: username });

  return {
    title: title,
    subTitle: state.reports.timeLabel,
    accountName: name,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
