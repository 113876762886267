import { connect } from 'react-redux';
import SMBAccountSettings from '../components/SMBAccountSettings';

import {
  toggleUploadModal,
  uploadLogo,
  getAccountInfo,
  updateAccountOption,
} from '../actions/smb_account_settings';

const mapStateToProps = state => ({
  msp: state.account.msp,
  showUploadModal: state.smbAccountSettings.showUpload,
  logo: state.smbAccountSettings.logo,
  processing: state.smbAccountSettings.processing,
  saving: state.smbAccountSettings.saving,
  wca: state.account.subscriptions.wca,
  passwordShow: state.smbAccountSettings.changes.hasOwnProperty(
    'remove_agent_password'
  ),
  fields: {
    ...state.smbAccountSettings.fields,
    ...state.smbAccountSettings.changes,
  },
  periods: state.smbAccountSettings.periods,
  updateOptions: state.smbAccountSettings.updateOptions,
});

const mapDispatchToProps = dispatch => ({
  initData: () => {
    dispatch(getAccountInfo());
  },
  openUploadModal: () => {
    dispatch(toggleUploadModal(true));
  },
  closeUploadModal: () => {
    dispatch(toggleUploadModal(false));
  },
  uploadLogo: logo => {
    dispatch(uploadLogo(logo));
  },
  updatePeriod: period => {
    dispatch(
      updateAccountOption({
        period: period.value,
      })
    );
  },
  updateTime: time => {
    dispatch(
      updateAccountOption({
        [time.target.name]: time.target.value,
      })
    );
  },
  updateAgentUpdate: option => {
    dispatch(
      updateAccountOption({
        updateSetting: option.value,
      })
    );
  },
  toggleSupport: field => {
    dispatch(
      updateAccountOption({
        support_enabled: field.target.checked,
      })
    );
  },
  toggleRemoveAgent: field => {
    dispatch(
      updateAccountOption({
        remove_agent_enabled: field.target.checked,
      })
    );
  },
  updateAgentPassword: field => {
    dispatch(
      updateAccountOption({
        remove_agent_password: field.target.value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SMBAccountSettings);
