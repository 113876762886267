import { connect } from 'react-redux';
import UserLogin from '../../components/signup/UserLogin';
import { setLogin, userLogin, updateFormFields } from '../../actions/signup';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  processing: state.signup.userLogin.submitting,
  error: state.signup.userLogin.error,
});

const mapDispatchToProps = dispatch => ({
  handleLogin: (username, password) => {
    Mixpanel.track('Trial / Existing Account-Sign In', {});
    dispatch(userLogin(username, password));
  },
  handleBack: () => {
    Mixpanel.track('Trial / Existing Account-Create', {});
    dispatch(setLogin(false));
  },
  updateField: event => {
    dispatch(
      updateFormFields('signinForm', {
        [event.target.name]: event.target.value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLogin);
