import { connect } from 'react-redux';
import Signup from '../../components/signup/Signup';
import { submitEmail, updateEmailAddress } from '../../actions/signup';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  page: state.signup.page,
  form: state.signup.userForm,
  email: state.signup.email,
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: event => {
    Mixpanel.track('Trial / Sign Up Form-Submit', {});
    event.preventDefault();
    dispatch(submitEmail());
  },
  updateEmailAddress: event => {
    Mixpanel.track('Trial / New Account-Activation Link', {
      email: event.target.value,
    });
    dispatch(updateEmailAddress(event.target.value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
