import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Translate, I18n } from 'react-i18nify';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ViewLink from './ViewLink';

import CudaMenu from '../CudaMenu';
import ModuleLoading from './ModuleLoading';
import CudaTableTitle from '../CudaTableTitle';

import Timestamp from '../../containers/Timestamp';

import { Mixpanel } from '../../lib/Mixpanel';

class ThreatTableComponent extends Component {
  state = {
    fields: ['name', 'user', 'count', 'version', 'timestamp'],
    icons: ['asc', 'none', 'none', 'none', 'none'],
    timeRangeIndex: 1,
  };

  toggle(index) {
    let value = this.state.icons[index];
    let icons = ['none', 'none', 'none', 'none', 'none'];
    if (value === 'asc') {
      value = 'desc';
    } else {
      value = 'asc';
    }

    icons[index] = value;
    this.props.onSort(value, this.state.fields[index]);
    this.setState({ icons: icons });
  }

  trackViewClick = () => {
    ReactGA.event({
      category: 'Dashboard',
      action: 'Click Blocked Files',
    });
  };

  onChangeTimerange = timeRangeIndex => {
    this.props.changeTimerange(this.options[timeRangeIndex].key);
    this.setState({ timeRangeIndex });
  };

  handleCSVExportClick = () => {
    const { downloadCsv } = this.props;
    Mixpanel.track('Dashboard / Advanced-Endpoint Threats Download CSV', {});
    ReactGA.event({
      category: 'Dashboard',
      action: 'Export CSV',
    });
    downloadCsv(this.options[this.state.timeRangeIndex].key);
  };

  options = [
    {
      key: 'day',
      label: I18n.t('shared.ranges.day'),
    },
    {
      key: 'week',
      label: I18n.t('shared.ranges.week'),
    },
    {
      key: 'month',
      label: I18n.t('shared.ranges.month'),
    },
  ];

  render() {
    const { classes, logs, loading, csvLoading } = this.props;
    const { timeRangeIndex } = this.state;
    const { options } = this;
    const threatClass = classNames(classes.threats, classes.centered);

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography className={classes.timeLabel} type="caption">
            {options[timeRangeIndex].label}
          </Typography>
          {csvLoading ? (
            <div className={classes.loadingBtn}>
              {' '}
              <CircularProgress />{' '}
            </div>
          ) : (
            <Button
              id="export-csv-btn"
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                this.handleCSVExportClick();
              }}
            >
              {I18n.t('shared.downloadCSV')}
            </Button>
          )}
          <div className={classes.wrapper}>
            {loading ? <ModuleLoading /> : null}
            <Table>
              <TableHead>
                {!loading ? (
                  <TableRow>
                    <TableCell>
                      <CudaTableTitle
                        value="shared.endpointName"
                        sort={true}
                        toggle={this.state.icons[0]}
                        onClick={() => {
                          this.toggle(0);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CudaTableTitle
                        value="shared.username"
                        sort={true}
                        toggle={this.state.icons[1]}
                        onClick={() => {
                          this.toggle(1);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.centered}
                      style={{ width: '5%' }}
                    >
                      <CudaTableTitle
                        value="shared.threats"
                        sort={true}
                        center={true}
                        toggle={this.state.icons[2]}
                        onClick={() => {
                          this.toggle(2);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CudaTableTitle
                        value="shared.lastThreat"
                        sort={true}
                        toggle={this.state.icons[4]}
                        onClick={() => {
                          this.toggle(4);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ width: '5%' }}
                      className={classes.centered}
                    >
                      <CudaTableTitle
                        value="shared.agentVersion"
                        sort={true}
                        center={true}
                        toggle={this.state.icons[3]}
                        onClick={() => {
                          this.toggle(3);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ width: '5%' }}
                      className={classes.centered}
                    >
                      <CudaTableTitle
                        value="shared.blockedFiles"
                        sort={false}
                        center={false}
                      />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableHead>
              <TableBody>
                {!loading && logs.length > 0
                  ? logs.map((n, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{n.name}</TableCell>
                          <TableCell>{n.user}</TableCell>
                          <TableCell
                            style={{ width: '5%' }}
                            className={threatClass}
                          >
                            {n.count}
                          </TableCell>
                          <TableCell>
                            <Timestamp timestamp={n.timestamp} />
                          </TableCell>
                          <TableCell
                            style={{ width: '5%' }}
                            className={classes.centered}
                          >
                            {n.version}
                          </TableCell>
                          <TableCell
                            style={{ width: '5%' }}
                            className={classes.centered}
                          >
                            <NavLink
                              onClick={() => {
                                this.trackViewClick();
                              }}
                              to={`/atp_logs?q=${n.name}`}
                              className={classes.link}
                            >
                              {I18n.t('components.dashboard.view')}
                            </NavLink>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
                {!loading && logs.length === 0 ? (
                  <TableRow>
                    <TableCell className={classes.noResults} colSpan="6">
                      <Translate value="shared.noResults" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Grid item xs={6}>
          <CudaMenu
            disabled={loading}
            customStyle={classes.timeMenu}
            options={options}
            selectedIndex={timeRangeIndex}
            onChange={value => {
              this.onChangeTimerange(value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ViewLink
            onClick={() => {
              Mixpanel.track(
                'Dashboard / Advanced-Endpoint Threats View All',
                {}
              );
            }}
            to="/atp_logs"
            value={I18n.t('components.dashboard.viewAll')}
          />
        </Grid>
      </Grid>
    );
  }
}
ThreatTableComponent.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      username: PropTypes.string,
      threatCount: PropTypes.number,
      agentVersion: PropTypes.string,
      lastThreat: PropTypes.string,
      blockedFiles: PropTypes.string,
      range: PropTypes.string,
      account_id: PropTypes.number,
    })
  ),
  downloadCsv: PropTypes.func,
  csvLoading: PropTypes.bool,
};

const styles = {
  wrapper: {
    overflow: 'scroll',
    position: 'relative',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  tableTitle: {
    textTransform: 'uppercase',
  },
  timeLabel: {
    paddingLeft: '20px',
  },
  centered: {
    textAlign: 'center',
  },
  threats: {
    color: '#DD1E31',
  },
  link: {
    color: '#0088CF',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  timeMenu: {
    border: 0,
    maxWidth: '140px',
  },
  loadingBtn: {
    position: 'absolute',
    top: '15px',
    right: '20px',
  },
  button: {
    float: 'right',
    top: '-61px',
    marginRight: '20px',
    marginBottom: '-50px',
  },
};

export default withStyles(styles, { name: 'TableStyles' })(
  ThreatTableComponent
);
