import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import Select from 'react-select';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import LocationCity from '@material-ui/icons/LocationCity';

import { AccountIcon } from '../icons';
import Auth from '../../lib/Auth';
import SessionAction from './SessionAction';
import Box from './Box';
import ActionButton from './ActionButton';

import { regions } from '../../lib/Env';

const customStyles = {
  control: base => ({
    ...base,
    border: '1px solid #DDDDDD',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontSize: '14px',
    color: '#555555',
    minHeight: 32,
    height: 32,
  }),
  container: base => ({
    ...base,
    height: 32,
    width: '100%',
  }),
  option: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  singleValue: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  input: base => ({
    ...base,
    margin: '2px 0',
  }),
};

class ExistingUserSelectAccount extends React.Component {
  state = {
    selectedAccount: 0,
    errors: {},
  };

  onChangeAccount = selectedAccount => {
    this.setState({
      selectedAccount,
    });
  };

  logOut = () => {
    const token = Auth.get_token();
    Auth.logout();
    this.props.deleteSession(token);
  };

  saveInfo = accountId => {
    if (accountId) {
      this.props.saveInfo(accountId);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          account: 'Required',
        },
      });
    }
  };

  render() {
    const {
      classes,
      accounts,
      processing,
      email,
      msp,
      updateField,
      region,
    } = this.props;
    const { selectedAccount, errors } = this.state;
    const { logOut, saveInfo, onChangeAccount } = this;
    const menuAccounts = accounts.map(account => ({
      key: account.account_id,
      label: account.account_name,
    }));

    return (
      <Box
        title={I18n.t('signup.selectAccount.title')}
        subTitle={I18n.t('signup.selectAccount.subTitle')}
      >
        <form
          onSubmit={event => {
            saveInfo(selectedAccount);
            event.preventDefault();
          }}
        >
          <Grid
            item
            data-test-id="existing-user-container"
            container
            alignItems="center"
            justify="center"
            className={classes.formDiv}
          >
            <Grid item xs={11} className={classes.formWrapper}>
              <FormControl fullWidth error={Boolean(errors.account)}>
                <div className={classes.inputWrapper}>
                  <AccountIcon />
                  <Select
                    data-test-id="existing-user-select"
                    name="existing-user-select"
                    styles={customStyles}
                    placeholder={I18n.t('signup.selectAccount.selectAccount')}
                    options={menuAccounts}
                    className={classes.selectDropdown}
                    error={errors.account}
                    value={menuAccounts.find(
                      account => account.key === selectedAccount
                    )}
                    onChange={selectedOption => {
                      onChangeAccount(selectedOption.key);
                    }}
                  />
                </div>
                <FormHelperText className={classes.helperText}>
                  {errors.account}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={11} className={classes.regionWrapper}>
              <div className={classes.regionHelper}>
                {I18n.t('signup.fillDetails.regionHelp')}
              </div>
              <div
                className={classes.inputWrapper}
                data-test-id="signup-region"
              >
                <LocationCity />
                <Select
                  styles={customStyles}
                  name="signup-region"
                  placeholder={I18n.t('signup.fillDetails.region')}
                  options={regions}
                  className={classes.selectDropdown}
                  value={regions.find(region => region.value === region)}
                  onChange={selectedOption => {
                    updateField({
                      target: {
                        name: 'region',
                        value: selectedOption.value || 'US',
                      },
                    });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={11}>
              <ActionButton
                data-test-id="btn-submit"
                type="submit"
                inProgress={processing}
                inProgressText={I18n.t('signup.selectedAccount.submitting')}
                className={classes.actionButton}
              >
                <Translate value="common.submit" />
              </ActionButton>
            </Grid>
          </Grid>
        </form>
        {msp && (
          <Grid item className={classes.msp}>
            <SessionAction
              data-test-id="msp-action"
              label=<Translate value="signup.barracudaMSP" dangerousHTML />
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SessionAction
            data-test-id="btn-signout"
            label={I18n.t('signup.selectAccount.notAccount', {
              email,
            })}
            buttonLabel={I18n.t('shared.signOut')}
            buttonAction={logOut}
          />
        </Grid>
      </Box>
    );
  }
}

const styles = {
  formDiv: {
    padding: 8,
    width: '400px',
  },
  errorText: {
    visibility: 'hidden',
    marginLeft: '30px',
    marginTop: '2px',
  },
  errorState: {
    visibility: 'visible',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: '10px',
      width: '100%',
    },
  },
  helperText: {
    marginTop: '4px',
    marginLeft: '30px',
  },
  formWrapper: {
    height: '48px',
  },
  actionButton: {
    width: '100%',
    marginLeft: '30px',
    marginRight: '0px',
  },
  msp: {
    marginBottom: '5px',
    '& a': {
      color: '#0088CE',
      textDecoration: 'none',
      fontWeight: 500,
    },
  },
  regionWrapper: {
    paddingTop: '0px !important',
  },
  regionHelper: {
    color: 'rgba(0,0,0,0.7)',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '300',
    paddingLeft: '31px',
  },
};

ExistingUserSelectAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteSession: PropTypes.func.isRequired,
  saveInfo: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  accounts: PropTypes.array.isRequired,
  msp: PropTypes.bool,
};

ExistingUserSelectAccount.defaultProps = {
  msp: false,
};

export default withStyles(styles, { name: 'ExistingUserSelectAccount' })(
  ExistingUserSelectAccount
);
