import { Cookies, removeCookie } from 'react-cookie';
import * as Env from './Env';
var jwt_decode = require('jwt-decode');

const SECONDS_IN_HALF_DAY = 43200;

const Auth = {
  timer: null,
  authenticated: false,
  sso_token: false,
  alreadyChecked: false,
  isAuthenticated() {
    return this.authenticated;
  },
  checkOnce() {
    const response = this.alreadyChecked;

    this.alreadyChecked = !this.alreadyChecked;

    return response;
  },
  clear_local_storage() {
    localStorage.removeItem(Env.ACCOUNT);
    localStorage.removeItem(Env.ACCOUNT_NAME);
    localStorage.removeItem(Env.SSO_REFRESH_TOKEN);
    localStorage.removeItem(Env.SSO_SESSION_STORE);
    localStorage.removeItem(Env.JWE_SESSION_STORE);
    localStorage.removeItem(Env.SSO_SUPPORT_INFO);

    document.cookie = `CLOUD_AT= ; domain=${Env.COOKIE_DOMAIN}; max-age=`;
    document.cookie = `BCS_REFRESH= ; domain=${Env.COOKIE_DOMAIN}; max-age=`;
    document.cookie = `BCS_TOKEN= ; domain=${Env.COOKIE_DOMAIN}; max-age=`;
    document.cookie = `BCS_REGION= ; domain=${Env.COOKIE_DOMAIN}; max-age=`;

    this.authenticated = false;
    this.sso_token = false;
  },
  logout() {
    this.authenticated = false;
    this.clear_local_storage();
  },
  setup_support(info) {
    this.authenticated = true;
    localStorage.setItem(Env.SSO_SUPPORT_INFO, JSON.stringify(info));
  },
  setup_region(region) {
    if (region) {
      document.cookie = `BCS_REGION=${region}; domain=${
        Env.COOKIE_DOMAIN
      }; max-age=${SECONDS_IN_HALF_DAY}`;
    }
  },
  get_region() {
    var cookie = new Cookies();
    return cookie.get('BCS_REGION');
  },
  setup_sso(token, refresh_token) {
    const dtoken = this.store_sso(token);
    this.store_refresh_token(refresh_token);
    document.cookie = `${Env.BCS_REFRESH_STORE}=${refresh_token}; domain=${
      Env.COOKIE_DOMAIN
    }; ; max-age=${SECONDS_IN_HALF_DAY}`;
    return dtoken;
  },
  store_refresh_token(token) {
    localStorage.setItem(Env.SSO_REFRESH_TOKEN, token);
    return token;
  },
  get_refresh_token() {
    var cookie = new Cookies();
    return (
      cookie.get(`${Env.BCS_REFRESH_STORE}`) ||
      localStorage.getItem(Env.SSO_REFRESH_TOKEN)
    );
  },
  get_sso_token(decode = true) {
    const support_info = localStorage.getItem(Env.SSO_SUPPORT_INFO);
    const token = localStorage.getItem(Env.SSO_SESSION_STORE);
    if (support_info && decode) {
      return JSON.parse(support_info);
    } else if (support_info && !decode) {
      throw new Error('Invalid usage during support session');
    }

    this.sso_token = true;
    if (decode) {
      return jwt_decode(token);
    }

    return token;
  },
  store_sso(token) {
    localStorage.setItem(Env.SSO_SESSION_STORE, token);
    this.sso_token = true;
    return jwt_decode(token);
  },
  clear_refresh_interval() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  refresh_token() {
    const refreshToken = localStorage.getItem(Env.SSO_REFRESH_TOKEN);
    return window
      .fetch(Env.TOKEN_URL, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
      .then(response => {
        return response.json();
      })
      .then(response => {
        this.setup_sso(
          response['sso']['id_token'],
          response['sso']['refresh_token']
        );
        this.store_token(response['jwe']);
        return response;
      });
  },
  store_token(token) {
    this.authenticated = true;
    localStorage.setItem(Env.JWE_SESSION_STORE, token);
    document.cookie = `BCS_TOKEN=${token}; domain=${
      Env.COOKIE_DOMAIN
    }; ; max-age=${SECONDS_IN_HALF_DAY}`;
  },
  get_token() {
    return localStorage.getItem(Env.JWE_SESSION_STORE);
  },
  authenticate(token, cb) {
    this.authenticated = true;

    // Store token in cookie
    // need to specify domain: ;domain=.barracuda.com;path=/
    var cookie = new Cookies();
    cookie.set(Env.SSO_SESSION_STORE, token);

    //setTimeout(cb, 100) // fake async
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

export default Auth;
