import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as Types from '../actions/dashboard';
import * as BasicFilterTypes from '../actions/basic_filtering';
import * as AppTypes from '../actions/app';
import { I18n } from 'react-i18nify';
import Api from './Api';

export const dashboard = (
  state = {
    endpoints: {
      active: 0,
      inactive: 0,
      licensed: 0,
    },
    threatCount: 0,
    threatCountLoading: false,
    threatCounts: [],
    threatCountsLoading: false,
    threatLogs: [],
    threatLogsLoading: false,
    blocked_infoLoading: false,
    categories: {
      blockedRequests: 0,
      prevBlockedRequests: 0,
      blockedCategories: [],
      blockedSupercategories: [],
    },
    dayCategories: {
      blockedRequests: 0,
      prevBlockedRequests: 0,
      blockedCategories: [],
      blockedSupercategories: [],
    },
    csv: {
      loading: false,
      range: '',
    },
  },
  action
) => {
  switch (action.type) {
    case Types.DASHBOARD_DOWNLOAD_CSV:
      return {
        ...state,
        csv: {
          loading: true,
          range: action.range,
        },
      };
    case Types.DASHBOARD_DOWNLOAD_CSV_SUCCESS:
    case Types.DASHBOARD_DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        csv: {
          ...state.csv,
          loading: false,
          range: '',
        },
      };
    case Types.DASHBOARD_LOADING:
    case Types.INIT_DATA:
      return {
        ...state,
        threatCountLoading: true,
        threatCountsLoading: true,
        threatLogsLoading: true,
        blocked_infoLoading: true,
      };
    case Types.DASHBOARD_UPDATE_MODULE:
      return {
        ...state,
        [`${action.module}Loading`]: true,
      };
    case Types.DASHBOARD_UPDATE_MODULE_DONE:
      return {
        ...state,
        [`${action.module}Loading`]: false,
      };
    case Types.SORT_LOGS:
      let orderBy = action.property;
      let data;

      if (orderBy === 'name' || orderBy === 'user') {
        data =
          action.order === 'desc'
            ? state.threatLogs.sort((a, b) =>
                b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1
              )
            : state.threatLogs.sort((a, b) =>
                a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1
              );
      } else {
        data =
          action.order === 'desc'
            ? state.threatLogs.sort((a, b) =>
                b[orderBy] < a[orderBy] ? -1 : 1
              )
            : state.threatLogs.sort((a, b) =>
                a[orderBy] < b[orderBy] ? -1 : 1
              );
      }

      return {
        ...state,
        threatLogs: data,
      };
    case Types.GET_ATP_DATA_SUCCESS:
      return {
        ...state,
        ...action.formattedResult,
        threatCountLoading: false,
        threatCountsLoading: false,
        threatLogsLoading: false,
        blocked_infoLoading: false,
      };
    default:
      return state;
  }
};

function* fetchLogs(module = undefined, range = 'week', forceReload = false) {
  try {
    const store = yield select();
    const catMap = store.basic_filtering.catMapping;
    const catV2Map = store.basic_filtering.catV2Mapping;
    const catV3Map = store.basic_filtering.catV3Mapping;
    let moduleAgg = module;
    let cat = {};
    let combinedCats = {};
    if (!catMap.loaded || forceReload) {
      const catsResult = yield call(Api.getData, {
        page: 'get_basic_policies',
      });
      // cat needs to contiain both v2 and v3
      // for dashboard display
      cat = {
        categories: catsResult['categories'],
        supercategories: catsResult['supercategories'],
      };
      combinedCats = {
        categories: {
          ...catsResult['catsv2']['categories'],
          ...catsResult['catsv3']['categories'],
        },
        supercategories: {
          ...catsResult['catsv2']['supercategories'],
          ...catsResult['catsv3']['supercategories'],
        },
      };
      yield put(
        BasicFilterTypes.storeLoadedCats(
          cat['categories'],
          cat['supercategories'],
          catsResult['catsv2'],
          catsResult['catsv3'],
          catsResult['wcs_version']
        )
      );
    } else {
      cat = {
        categories: catMap['categories'],
        supercategories: catMap['supercategories'],
      };
      combinedCats = {
        categories: {
          ...catV2Map['categories'],
          ...catV3Map['categories'],
        },
        supercategories: {
          ...catV2Map['supercategories'],
          ...catV3Map['supercategories'],
        },
      };
    }

    if (['threatCount', 'threatCounts', 'threatLogs'].indexOf(module) !== -1) {
      moduleAgg = 'agent_threats';
    }

    const result = yield call(Api.getData, {
      page: 'dashboard',
      range,
      account_id: store.account.selected,
      modules: moduleAgg,
      time_zone: store.account.time_zone,
    });
    yield put(
      Types.getATPDataSuccess({
        ...result,
        wcs_cats: cat,
        combined_cats: combinedCats,
        module,
      })
    );
    yield put(
      Types.updateModuleDone({
        module,
      })
    );
  } catch (e) {
    console.log('whaaat: ', e);
    yield put(AppTypes.error(e.message));
    yield put(Types.getATPDataFailure(e.error));
  }
}

function* changeTime() {
  yield call(delay, 500);
  yield put(Types.dashboardLoading());
  yield fetchLogs();
}

function* initData() {
  yield fetchLogs(undefined, 'week', true);
}

function* updateModule(action) {
  yield fetchLogs(action.module, action.timeRange);
}

function* downloadCsv() {
  try {
    const store = yield select();
    const params = {
      account_id: store.account.selected,
      job_type: 'dashboard_csv',
      range: store.dashboard.csv.range,
      timezone: store.account.timezone,
    };

    const accountID = store.account.selected;
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(
          Api.csv.download,
          result.presignedURL,
          'BCS Top Endpoints by Threats.csv'
        );
        break;
      }
    }

    yield put(Types.downloadCsvSuccess());
  } catch (e) {
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
    yield put(Types.downloadCsvFailure(e));
  }
}

export function* dashboardReducerFlow() {
  yield takeEvery(Types.INIT_DATA, initData);
  yield takeLatest(Types.CHANGE_TIME, changeTime);
  yield takeLatest(Types.DASHBOARD_UPDATE_MODULE, updateModule);
  yield takeLatest(Types.DASHBOARD_DOWNLOAD_CSV, downloadCsv);
}
