import { connect } from 'react-redux';
import { I18n } from 'react-i18nify';
import ChipInput from '../components/ChipInput';
import { updateAccountOption } from '../actions/smb_account_settings';

const mapStateToProps = (state, ownProps) => ({
  inputValue:
    state.smbAccountSettings.changes.localDomains ||
    state.smbAccountSettings.fields.localDomains,
  selectedIndex:
    state.smbAccountSettings.changes.localDomainSelected ||
    state.smbAccountSettings.fields.localDomainSelected,
  iconClass: ownProps.iconClass,
  icon: ownProps.icon,
});

const mapDispatchToProps = dispatch => ({
  filterInput: value => {
    return value.replace(/\/.*/, '');
  },
  onSelectIndex: index => {
    dispatch(
      updateAccountOption({
        localDomainSelected: index,
      })
    );
  },
  onChange: value => {
    dispatch(
      updateAccountOption({
        localDomains: value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
