import * as AtpActionTypes from '../actions/atp';
import * as AppTypes from '../actions/app';
import { delay } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Api from './Api';

import { Mixpanel } from '../lib/Mixpanel';

function removeExclusion(state, type, value) {
  if (type === AtpActionTypes.EXCLUSION_TYPE_FILE) {
    return {
      ...state,
      fileExclusion: state.fileExclusion.filter(f => {
        return f !== value;
      }),
    };
  } else if (type === AtpActionTypes.EXCLUSION_TYPE_PATH) {
    return {
      ...state,
      pathExclusion: state.pathExclusion.filter(f => {
        return f !== value;
      }),
    };
  } else if (type === AtpActionTypes.EXCLUSION_TYPE_PROCESS) {
    return {
      ...state,
      processExclusion: state.processExclusion.filter(f => {
        return f !== value;
      }),
    };
  }

  return state;
}

function map_atp_to_api(state) {
  return {
    auto_remediate: state.autoRemediate.value,
    scan_ms_office: state.scanMSOffice.value,
    scan_executables: state.executables.value,
    scan_pdf_documents: state.pdfDocuments.value,
    scan_archive_files: state.archiveFiles.value,
    block_password_protected_doc: state.blockedPwProtectedOfficeDoc.value,
    block_encrypted_archives: state.blockedPwProtectedArchiveFile.value,
    scan_removable_drive: state.scanExclusion.value,
    file_exclusion: state.fileExclusion,
    path_exclusion: state.pathExclusion,
    process_exclusion: state.processExclusion,
    cpa_enabled: state.cpaEnabled.value,
  };
}

const track_events = {
  cpaEnabled: 'Threat Policy / Malware Prevention',
  autoRemediate: {
    false: 'Threat Policy / Quarantine Suspicious Files',
    true: 'Threat Policy / Allow Suspicious Files',
  },
  scanMSOffice: 'Threat Policy / Scan Microsoft Office Files',
  executables: 'Threat Policy / Scan Executable Files',
  pdfDocuments: 'Threat Policy / Scan PDF Files',
  archiveFiles: 'Threat Policy / Scan Compressed Files',
  blockedPwProtectedOfficeDoc: {
    true: 'Threat Policy / Quarantine Password Protected Files',
    false: 'Threat Policy / Allow Password Protected Files',
  },
  blockedPwProtectedArchiveFile: {
    true: 'Threat Policy / Quarantine Encrypted Files',
    false: 'Threat Policy / Allow Encrypted Files',
  },
  scanExclusion: 'Threat Policy / Scan Removable Drives',
};

export const atp = (
  state = {
    scanMSOffice: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    executables: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    pdfDocuments: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    archiveFiles: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    blockedPwProtectedOfficeDoc: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    blockedPwProtectedArchiveFile: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    scanExclusion: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    autoRemediate: {
      prevValue: false,
      value: false,
      isUpdating: false,
      updateError: false,
    },
    cpaEnabled: {
      prevValue: false,
      value: true,
      isUpdating: false,
      updateError: false,
    },
    customExclusion: 'ALL',
    fileExclusion: [],
    pathExclusion: [],
    processExclusion: [],
  },
  action
) => {
  switch (action.type) {
    case AtpActionTypes.TOGGLE_SCAN:
      if (typeof track_events[action.setting] === 'object') {
        Mixpanel.track(
          track_events[action.setting][!state[action.setting].value],
          {}
        );
      } else {
        Mixpanel.track(track_events[action.setting], {});
      }
      return {
        ...state,
        [action.setting]: {
          ...state[action.setting],
          value: !state[action.setting].value,
          isUpdating: true,
        },
      };
    case AtpActionTypes.ATP_SETTINGS_INIT:
      return {
        ...state,
        scanMSOffice: {
          ...state.scanMSOffice,
          isUpdating: true,
        },
        executables: {
          ...state.executables,
          isUpdating: true,
        },
        pdfDocuments: {
          ...state.pdfDocuments,
          isUpdating: true,
        },
        archiveFiles: {
          ...state.archiveFiles,
          isUpdating: true,
        },
        autoRemediate: {
          ...state.autoRemediate,
          isUpdating: true,
        },
        blockedPwProtectedOfficeDoc: {
          ...state.blockedPwProtectedOfficeDoc,
          isUpdating: true,
        },
        blockedPwProtectedArchiveFile: {
          ...state.blockedPwProtectedArchiveFile,
          isUpdating: true,
        },
        scanExclusion: {
          ...state.scanExclusion,
          isUpdating: true,
        },
        cpaEnabled: {
          ...state.cpaEnabled,
          isUpdating: true,
        },
      };
    case AtpActionTypes.ATP_GET_SUCCESS:
      return {
        ...state,
        scanMSOffice: {
          ...state.scanMSOffice,
          isUpdating: false,
          value: action.result.scan_ms_office,
          prevValue: action.result.scan_ms_office,
        },
        executables: {
          ...state.executables,
          isUpdating: false,
          value: action.result.scan_executables,
          prevValue: action.result.scan_executables,
        },
        pdfDocuments: {
          ...state.pdfDocuments,
          isUpdating: false,
          value: action.result.scan_pdf_documents,
          prevValue: action.result.scan_pdf_documents,
        },
        archiveFiles: {
          ...state.archiveFiles,
          isUpdating: false,
          value: action.result.scan_archive_files,
          prevValue: action.result.scan_archive_files,
        },
        autoRemediate: {
          ...state.autoRemediate,
          isUpdating: false,
          value: action.result.auto_remediate,
          prevValue: action.result.auto_remediate,
        },
        blockedPwProtectedOfficeDoc: {
          ...state.blockedPwProtectedOfficeDoc,
          isUpdating: false,
          value: action.result.block_pw_doc,
          prevValue: action.result.block_pw_doc,
        },
        blockedPwProtectedArchiveFile: {
          ...state.blockedPwProtectedArchiveFile,
          isUpdating: false,
          value: action.result.block_encrypted_archives,
          prevValue: action.result.block_encrypted_archives,
        },
        scanExclusion: {
          ...state.scanExclusion,
          isUpdating: false,
          value: action.result.scan_removable_drive,
          prevValue: action.result.scan_removable_drive,
        },
        cpaEnabled: {
          ...state.cpaEnabled,
          isUpdating: false,
          value: action.result.cpa_enabled,
          prevValue: action.result.cpa_enabled,
        },
        fileExclusion: action.result.file_exclusion,
        pathExclusion: action.result.path_exclusion,
        processExclusion: action.result.process_exclusion,
      };
    case AtpActionTypes.TOGGLE_SCAN_SUCCESS:
      return {
        ...state,
        scanMSOffice: {
          ...state.scanMSOffice,
          prevValue: state.scanMSOffice.value,
          isUpdating: false,
        },
        executables: {
          ...state.executables,
          prevValue: state.executables.value,
          isUpdating: false,
        },
        pdfDocuments: {
          ...state.pdfDocuments,
          prevValue: state.pdfDocuments.value,
          isUpdating: false,
        },
        archiveFiles: {
          ...state.archiveFiles,
          prevValue: state.archiveFiles.value,
          isUpdating: false,
        },
        autoRemediate: {
          ...state.autoRemediate,
          prevValue: state.autoRemediate.value,
          isUpdating: false,
        },
        blockedPwProtectedOfficeDoc: {
          ...state.blockedPwProtectedOfficeDoc,
          prevValue: state.blockedPwProtectedOfficeDoc.value,
          isUpdating: false,
        },
        blockedPwProtectedArchiveFile: {
          ...state.blockedPwProtectedArchiveFile,
          prevValue: state.blockedPwProtectedArchiveFile.value,
          isUpdating: false,
        },
        cpaEnabled: {
          ...state.cpaEnabled,
          isUpdating: false,
          prevValue: state.cpaEnabled.value,
        },
        scanExclusion: {
          ...state.scanExclusion,
          prevValue: state.scanExclusion.value,
          isUpdating: false,
        },
      };
    case AtpActionTypes.ATP_GET_FAILURE:
    case AtpActionTypes.TOGGLE_SCAN_FAILURE:
      return {
        ...state,
        scanMSOffice: {
          ...state.scanMSOffice,
          value: state.scanMSOffice.prevValue,
          isUpdating: false,
        },
        executables: {
          ...state.executables,
          value: state.executables.prevValue,
          isUpdating: false,
        },
        pdfDocuments: {
          ...state.pdfDocuments,
          value: state.pdfDocuments.prevValue,
          isUpdating: false,
        },
        archiveFiles: {
          ...state.archiveFiles,
          value: state.archiveFiles.prevValue,
          isUpdating: false,
        },
        autoRemediate: {
          ...state.autoRemediate,
          value: state.autoRemediate.prevValue,
          isUpdating: false,
        },
        blockedPwProtectedOfficeDoc: {
          ...state.blockedPwProtectedOfficeDoc,
          value: state.blockedPwProtectedOfficeDoc.prevValue,
          isUpdating: false,
        },
        blockedPwProtectedArchiveFile: {
          ...state.blockedPwProtectedArchiveFile,
          value: state.blockedPwProtectedArchiveFile.prevValue,
          isUpdating: false,
        },
        scanExclusion: {
          ...state.scanExclusion,
          value: state.scanExclusion.prevValue,
          isUpdating: false,
        },
        cpaEnabled: {
          ...state.cpaEnabled,
          isUpdating: false,
          value: state.cpaEnabled.value,
        },
      };
    case AtpActionTypes.TOGGLE_CUSTOM_EXCLUSION:
      return {
        ...state,
        customExclusion: action.value,
      };
    case AtpActionTypes.ADD_CUST_FILE_EXCLUSION_SUCCESS:
      return {
        ...state,
        fileExclusion: state.fileExclusion.concat(action.value),
      };
    case AtpActionTypes.ADD_CUST_PATH_EXCLUSION_SUCCESS:
      return {
        ...state,
        pathExclusion: state.pathExclusion.concat(action.value),
      };
    case AtpActionTypes.ADD_CUST_PROCESS_EXCLUSION_SUCCESS:
      return {
        ...state,
        processExclusion: state.processExclusion.concat(action.value),
      };
    case AtpActionTypes.REMOVE_CUST_EXCLUSION_SUCCESS:
    case AtpActionTypes.EDIT_CUST_EXCLUSION_SUCCESS:
      return {
        ...state,
        fileExclusion: action.atp.fileExclusion,
        pathExclusion: action.atp.pathExclusion,
        processExclusion: action.atp.processExclusion,
      };
    default:
      return state;
  }
};

function* initATP() {
  try {
    const result = yield call(Api.getATP);
    yield put(AtpActionTypes.getATPSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(AtpActionTypes.getATPFailure(e.error));
  }
}

function* toggleSetting(action) {
  try {
    const store = yield select();
    const body = map_atp_to_api(store.atp);
    yield call(Api.updateATP, body);
    yield put(AtpActionTypes.toggleSettingSuccess(action.setting));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(AtpActionTypes.toggleSettingFailure(action.setting));
  }
}

function* addFileCustomExclusion(action) {
  try {
    const store = yield select();
    let atp = {
      ...store.atp,
      fileExclusion: [...store.atp.fileExclusion],
    };
    atp.fileExclusion.push(action.value);
    const body = map_atp_to_api(atp);
    yield call(Api.updateATP, body);
    yield put(AtpActionTypes.addFileCustomExclusionSuccess(action.value));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(AtpActionTypes.addFileCustomExclusionFailure());
  }
}

function* addPathCustomExclusion(action) {
  try {
    const store = yield select();
    let atp = {
      ...store.atp,
      pathExclusion: [...store.atp.pathExclusion],
    };
    atp.pathExclusion.push(action.value);
    const body = map_atp_to_api(atp);
    yield call(Api.updateATP, body);
    yield put(AtpActionTypes.addPathCustomExclusionSuccess(action.value));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(AtpActionTypes.addPathCustomExclusionFailure());
  }
}

function* addProcessCustomExclusion(action) {
  try {
    const store = yield select();
    let atp = {
      ...store.atp,
      processExclusion: [...store.atp.processExclusion],
    };
    atp.processExclusion.push(action.value);
    const body = map_atp_to_api(atp);
    yield call(Api.updateATP, body);
    yield put(AtpActionTypes.addProcessCustomExclusionSuccess(action.value));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(AtpActionTypes.addProcessCustomExclusionFailure());
  }
}

function* editCustomExclusion(action) {
  try {
    const store = yield select();
    let atp = {
      ...store.atp,
      fileExclusion: [...store.atp.fileExclusion],
      pathExclusion: [...store.atp.pathExclusion],
      processExclusion: [...store.atp.processExclusion],
    };
    const updatedState = removeExclusion(
      atp,
      action.prevType,
      action.prevValue
    );
    switch (action.cType) {
      case AtpActionTypes.EXCLUSION_TYPE_FILE:
        atp = {
          ...updatedState,
          fileExclusion: updatedState.fileExclusion.concat(action.cValue),
        };
        break;
      case AtpActionTypes.EXCLUSION_TYPE_PATH:
        atp = {
          ...updatedState,
          pathExclusion: updatedState.pathExclusion.concat(action.cValue),
        };
        break;
      case AtpActionTypes.EXCLUSION_TYPE_PROCESS:
        atp = {
          ...updatedState,
          processExclusion: updatedState.processExclusion.concat(action.cValue),
        };
        break;
      default:
        break;
    }
    const body = map_atp_to_api(atp);
    yield call(Api.updateATP, body);
    yield put(AtpActionTypes.editCustomExclusionSuccess(atp));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(AtpActionTypes.editCustomExclusionFailure());
  }
}

function* removeCustomExclusion(action) {
  try {
    const store = yield select();
    let atp = {
      ...store.atp,
      fileExclusion: [...store.atp.fileExclusion],
      pathExclusion: [...store.atp.pathExclusion],
      processExclusion: [...store.atp.processExclusion],
    };
    const updatedState = removeExclusion(
      atp,
      action.prevType,
      action.prevValue
    );
    switch (action.fileType) {
      case AtpActionTypes.EXCLUSION_TYPE_FILE:
        atp = {
          ...updatedState,
          fileExclusion: atp.fileExclusion.filter(f => {
            return f !== action.value;
          }),
        };
        break;
      case AtpActionTypes.EXCLUSION_TYPE_PATH:
        atp = {
          ...updatedState,
          pathExclusion: atp.pathExclusion.filter(f => {
            return f !== action.value;
          }),
        };
        break;
      case AtpActionTypes.EXCLUSION_TYPE_PROCESS:
        atp = {
          ...updatedState,
          processExclusion: atp.processExclusion.filter(f => {
            return f !== action.value;
          }),
        };
        break;
      default:
        break;
    }
    const body = map_atp_to_api(atp);
    yield call(Api.updateATP, body);
    yield put(AtpActionTypes.removeCustomExclusionSuccess(atp));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(AtpActionTypes.removeCustomExclusionFailure());
  }
}

export function* atpReducerFlow() {
  yield takeEvery(
    AtpActionTypes.ADD_CUST_FILE_EXCLUSION,
    addFileCustomExclusion
  );
  yield takeEvery(
    AtpActionTypes.ADD_CUST_PATH_EXCLUSION,
    addPathCustomExclusion
  );
  yield takeEvery(
    AtpActionTypes.ADD_CUST_PROCESS_EXCLUSION,
    addProcessCustomExclusion
  );
  yield takeEvery(AtpActionTypes.EDIT_CUST_EXCLUSION, editCustomExclusion);
  yield takeEvery(AtpActionTypes.REMOVE_CUST_EXCLUSION, removeCustomExclusion);
  yield takeEvery(AtpActionTypes.TOGGLE_SCAN, toggleSetting);
  yield takeEvery(AtpActionTypes.ATP_SETTINGS_INIT, initATP);
}
