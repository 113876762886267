import * as Types from '../actions/devices';
import * as AppTypes from '../actions/app';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Api from './Api';
import { delay } from 'redux-saga';
import { I18n } from 'react-i18nify';

export const devices = (
  state = {
    init: false,
    loading: false,
    agent_info: [],
    agent_threats: {},
    csv: {
      csvLoading: false,
      range: '',
    },
  },
  action
) => {
  switch (action.type) {
    case Types.GET_DEVICES_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        loading: false,
        agent_info: action.agent_info,
        agent_threats: action.agent_threats,
      };
    case Types.GET_DEVICES_DATA_FAILURE:
      return {
        ...state,
        init: true,
        loading: false,
      };
    case Types.ENDPOINT_LOGS_DOWNLOAD_CSV:
      return {
        ...state,
        csv: {
          ...state.csv,
          range: action.range,
          csvLoading: true,
        },
      };

    case Types.ENDPOINT_LOGS_DOWNLOAD_CSV_SUCCESS:
    case Types.ENDPOINT_LOGS_DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        csv: {
          ...state.csv,
          range: '',
          csvLoading: false,
        },
      };

    case Types.DEVICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

function* getDeviceList() {
  try {
    const store = yield select();
    yield put(Types.deviceLoading());
    const result = yield call(Api.getDeviceList, {
      page: 'devices',
      range: store.atp_shared.range,
      account_id: store.account.selected,
      time_zone: store.account.time_zone,
    });
    yield put(Types.getDeviceListSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getDeviceListFailure(e.error));
  }
}

function* downloadCsv() {
  try {
    const store = yield select();
    const params = {
      job_type: 'endpoint_logs_csv',
      range: store.devices.csv.range,
      timezone: store.account.timezone,
    };

    const accountID = store.account.selected;
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(Api.csv.download, result.presignedURL, 'endpoint.csv');
        break;
      }
    }

    yield put(Types.downloadCsvSuccess());
  } catch (e) {
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
    yield put(Types.downloadCsvFailure(e));
  }
}

export function* devicesReducerFlow() {
  yield takeEvery(Types.INIT_DEVICES_LIST, getDeviceList);
  yield takeLatest(Types.DEVICES_CHANGE_TIME, getDeviceList);
  yield takeEvery(Types.ENDPOINT_LOGS_DOWNLOAD_CSV, downloadCsv);
}
