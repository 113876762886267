import { connect } from 'react-redux';
import { I18n } from 'react-i18nify';
import ChipInput from '../../components/ChipInput';
import {
  selectDenyIndex,
  changeDenyDomains,
} from '../../actions/basic_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = (state, ownProps) => ({
  inputValue: state.basic_filtering.exceptionConfig.denyDomains,
  selectedIndex: state.basic_filtering.exceptionConfig.denySelectedIndex,
  placeholder: I18n.t('components.basicFiltering.denyPlaceholder'),
  iconClass: ownProps.iconClass,
  icon: ownProps.icon,
});

const mapDispatchToProps = dispatch => ({
  onSelectIndex: index => {
    dispatch(selectDenyIndex(index));
  },
  onChange: value => {
    Mixpanel.track(
      'DNS Filtering / Add Location Dialog / Step 2-Blocked Domains',
      {}
    );
    dispatch(changeDenyDomains(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
