import React from 'react';
import { I18n } from 'react-i18nify';
import { Translate } from 'react-i18nify';
import { connect } from 'react-redux';
import { updateAccountOption } from '../../actions/smb_account_settings';
import CpuThrottle from '../../components/smb_account_settings/cpu_throttle';

const mapStateToProps = state => ({
  value:
    'cpuMax' in state.smbAccountSettings.changes
      ? state.smbAccountSettings.changes.cpuMax
      : state.smbAccountSettings.fields.cpuMax,
});

const mapDispatchToProps = dispatch => ({
  onChange: event => {
    console.log('the value: ', event, event.target.value);
    dispatch(
      updateAccountOption({
        cpuMax: event.target.value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CpuThrottle);
