import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { I18n } from 'react-i18nify';
import RadioSelector from '../../components/common/RadioSelector';

import { openModal } from '../../actions/user_authentication';

import { Mixpanel } from '../../lib/Mixpanel';

const track_event = {
  directory: 'Users / Directory Services',
  local: 'Users / Manually Configure Users',
};

const mapStateToProps = state => ({
  value: state.userAuthentication.userType,
  label: I18n.t('userAuthentication.configureUsers'),
  options: [
    {
      value: 'directory',
      label: I18n.t('userAuthentication.directoryOption'),
    },
    {
      value: 'local',
      label: I18n.t('userAuthentication.localOption'),
    },
  ],
});

const mapDispatchToProps = dispatch => ({
  handleChange: e => {
    Mixpanel.track(track_event[e.target.value], {});
    dispatch(
      openModal('confirmTypeChange', {
        type: e.target.value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RadioSelector));
