import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router';

import BasicFilterChart from '../../containers/reports/BasicFilterChart';
import ReportAreaChart from '../../containers/ReportAreaChart';
import ReportPieChart from '../../containers/ReportPieChart';
import ReportTable from '../../containers/ReportTable';
import ReportPieTable from '../../containers/ReportPieTable';
import ReportBarTable from '../../containers/ReportBarTable';
import BasicFilterTable from '../../containers/reports/BasicFilterTable';
import UserReport from '../../containers/UserReport';
import PerUserReport from '../../containers/PerUserReport';

import Paper from '../CudaPaper';

import Auth from '../../lib/Auth';

const styles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: '300',
    color: 'rgba(0,0,0,0.9)',
  },
  subtitle: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
  },
  graphWrapper: {
    padding: '20px',
    margin: '20px',
  },
  dataTableWrapper: {
    margin: '20px',
  },
  tableWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    margin: '25px',
  },
});

const customReport = {
  agent_version: true,
  'blocked-categories': true,
  'blocked-supercategories': true,
};

class DataTable extends Component {
  componentDidMount() {
    const { runReport } = this.props;
    const { report, range, rows, index, user } = this.props.match.params;
    const dtoken = Auth.get_sso_token();
    this.props.retrieveAccount(
      dtoken.email,
      dtoken.account,
      dtoken.accounts,
      dtoken.services,
      dtoken.sub,
      dtoken.zoneinfo
    );
    runReport(report, range, rows, index, user);
  }

  render() {
    const { classes, selectedReportType, loading } = this.props;

    console.log('the selected report type: ', selectedReportType);
    const multiReport =
      selectedReportType === 'user-reports' ||
      selectedReportType === 'per-user-reports';

    return (
      <div>
        {!loading && selectedReportType === 'user-reports' && (
          <div>
            <UserReport />
          </div>
        )}
        {!loading && selectedReportType === 'per-user-reports' && (
          <div>
            <PerUserReport />
          </div>
        )}
        {!loading && !multiReport && (
          <div>
            <Paper className={classes.graphWrapper}>
              <Grid container spacing={24} justify="space-between">
                <Grid item xs={12}>
                  {selectedReportType === 'all' ? <ReportAreaChart /> : null}
                  {customReport[selectedReportType] ? (
                    <BasicFilterChart />
                  ) : null}
                  {selectedReportType !== 'all' &&
                  !customReport[selectedReportType] ? (
                    <ReportPieChart />
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.dataTableWrapper}>
              <Grid container spacing={24} justify="space-between">
                <Grid item xs={12} className={classes.tableWrapper}>
                  {customReport[selectedReportType] ? (
                    <BasicFilterTable />
                  ) : null}
                  {selectedReportType === 'all' ? <ReportTable /> : null}
                  {selectedReportType === 'agent_version' ? (
                    <ReportBarTable />
                  ) : null}
                  {selectedReportType === 'users' ? (
                    <ReportPieTable col1="user" col2="threats" />
                  ) : null}
                  {selectedReportType === 'files' ? (
                    <ReportPieTable col1="fileType" col2="threats" />
                  ) : null}
                  {selectedReportType === 'endpoints' ? (
                    <ReportPieTable col1="endpoints" col2="threats" />
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
        {loading && (
          <div className={classes.center}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

DataTable.propTypes = {
  selectedReportType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  runReport: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(DataTable));
