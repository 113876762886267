import React from 'react';
import { Translate, I18n } from 'react-i18nify';
import { call, select, put, takeEvery, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as Types from '../actions/wf_logs';
import { storeLoadedCats } from '../actions/basic_filtering';
import { getActiveDirectorySuccess } from '../actions/account';
import { getQueryLabels, getWfLogs } from '../selectors/wf_logs';
import Api from './Api';
import Auth from '../lib/Auth';
import * as AppTypes from '../actions/app';

import { getAccountId } from '../selectors/account';

const update = require('immutability-helper');

const timeOptions = [
  {
    key: 'day',
    label: <Translate value="shared.ranges.day" />,
  },
  {
    key: 'week',
    label: <Translate value="shared.ranges.week" />,
  },
  {
    key: 'month',
    label: <Translate value="shared.ranges.month" />,
  },
];

export default (
  state = {
    logs: [],
    page: 0,
    rowsPerPage: 20,
    logCount: 0,
    loading: false,
    sort: {
      field: 'date',
      direction: 'desc',
    },
    timeOptions,
    timeSelectedIndex: 2,
    range: 'month',
    locations: {},
    exporting: false,
    refreshing: false,
    query: '',
    queryField: 0,
    queryFields: [
      {
        label: I18n.t('components.wfLogs.categories'),
        field: 'categories',
      },
      {
        label: I18n.t('components.wfLogs.supercategories'),
        field: 'supercategories',
      },
      {
        label: I18n.t('components.wfLogs.location'),
        field: 'locations',
      },
      {
        label: I18n.t('components.wfLogs.fullName'),
        field: 'users',
      },
      {
        label: I18n.t('components.wfLogs.userId'),
        field: 'name',
        type: 'input',
      },
      {
        label: I18n.t('components.wfLogs.action'),
        field: 'actions',
      },
      {
        label: I18n.t('components.wfLogs.url'),
        field: 'urls',
        type: 'input',
      },
    ],
    columnTypes: [
      'date',
      'action',
      'user',
      'rule',
      'supercategory',
      'category',
      'url',
    ],
    columns: {
      date: {
        selected: true,
        label: I18n.t('shared.date'),
      },
      action: {
        selected: true,
        label: I18n.t('components.wfLogs.action'),
      },
      user: {
        selected: true,
        label: I18n.t('components.wfLogs.userId'),
      },
      rule: {
        selected: true,
        label: I18n.t('components.wfLogs.rule'),
      },
      category: {
        selected: true,
        label: I18n.t('components.wfLogs.categories'),
      },
      supercategory: {
        selected: false,
        label: I18n.t('components.wfLogs.supercategories'),
      },
      location: {
        selected: false,
        label: 'Location',
      },
      url: {
        selected: true,
        label: I18n.t('components.wfLogs.url'),
      },
    },
  },
  action
) => {
  switch (action.type) {
    case Types.WF_LOGS_GET_DATA:
    case Types.WF_LOGS_GET_MORE_LOGS:
      return {
        ...state,
        loading: true,
      };
    case Types.WF_LOGS_INIT_DATA:
      return {
        ...state,
        loading: true,
        query: '',
      };
    case Types.WF_LOGS_EXPORT_CSV:
      return {
        ...state,
        exporting: true,
      };
    case Types.WF_LOGS_EXPORT_CSV_SUCCESS:
    case Types.WF_LOGS_EXPORT_CSV_FAILURE:
      return {
        ...state,
        exporting: false,
      };
    case Types.WF_LOGS_QUERY_DATA:
      return {
        ...state,
        page: 0,
        loading: true,
      };
    case Types.WF_LOGS_UPDATE_FIELD:
      return {
        ...state,
        query: '',
        queryField: action.field,
      };
    case Types.WF_LOGS_UPDATE_QUERY:
      return {
        ...state,
        query: action.params.query,
      };
    case Types.WF_LOGS_GET_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: [...state.logs, ...action.logs],
      };
    case Types.WF_LOGS_GET_SUCCESS:
      return {
        ...state,
        page: 0,
        logs: action.logs,
        logCount: action.logCount,
        loading: false,
      };
    case Types.WF_LOGS_GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.WF_LOGS_CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        rowsPerPage: action.rowsPerPage,
      };
    case Types.WF_LOGS_UPDATE_SORT:
      return {
        ...state,
        loading: true,
        sort: action.sort,
      };
    case Types.WF_LOGS_STORE_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
      };
    case Types.WF_LOGS_TOGGLE_COLUMN:
      return {
        ...state,
        columns: update(state.columns, {
          [action.column]: {
            selected: {
              $set: action.checked,
            },
          },
        }),
      };
    case Types.WF_LOGS_UPDATE_RANGE:
      return {
        ...state,
        page: 0,
        loading: true,
        timeSelectedIndex: action.index,
        range: timeOptions[action.index].key,
      };
    default:
      return state;
  }
};

function generateParams(store, actionParams = {}) {
  const query = store.wfLogs.query || {};
  const field = store.wfLogs.queryFields[store.wfLogs.queryField].field;

  const params = {
    ...actionParams,
    order: store.wfLogs.sort.direction,
    range: store.wfLogs.range,
    account_id: store.selected,
    page: 'wf_logs',
  };

  if (query) {
    switch (field) {
      case 'categories':
        if (query.value || query.value === 0) {
          params.cats = [query.value].join(',');
        }
        break;
      case 'supercategories':
        if (query.value) {
          params.supercats = [query.value].join(',');
        }
        break;
      case 'locations':
        if (query.value) {
          params.locations = [query.value].join(',');
        }
        break;
      case 'actions':
        if (query.value) {
          params.actions = [query.value].join(',');
        }
        break;
      case 'name':
        if (query.value) {
          params.username = [query.value].join(',');
        }
        break;
      case 'users':
        if (query.value) {
          params.users = [query.value].join(',');
        }
        break;
      case 'urls':
        if (query.value) {
          params.url = query.value;
        }
        break;
      default:
        break;
    }
  }

  return params;
}

function* fetchLogs(action) {
  try {
    const store = yield select(getQueryLabels);
    const params = generateParams(store, action.params);

    const result = yield call(Api.getData, params);
    yield put(Types.getSuccess(result));
  } catch (e) {
    yield put(Types.getFailure(e));
  }
}

function* fetchMoreLogs(action) {
  try {
    const store = yield select(getQueryLabels);
    const params = generateParams(store, action.params);

    const result = yield call(Api.getData, params);
    yield put(Types.getMoreSuccess(result));
  } catch (e) {
    yield put(Types.getFailure(e));
  }
}

function* changePage() {
  const store = yield select(getWfLogs);
  const { page, rowsPerPage, logs } = store.wfLogs;

  if (page * rowsPerPage + 1 > logs.length) {
    yield put(Types.getMoreLogs(logs[logs.length - 1].sort));
  }
}

function* exportCSV() {
  try {
    const store = yield select(getQueryLabels);
    const params = generateParams(store, {
      timezone: store.timezone,
      job_type: 'wf_logs_csv',
    });
    const accountID = yield select(getAccountId);
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(
          Api.csv.download,
          result.presignedURL,
          'BCS Web Filtering Logs.csv'
        );
        break;
      }
    }

    yield put(Types.exportCSVSuccess());
  } catch (e) {
    yield put(Types.exportCSVFailure(e));
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
  }
}

function* initData(action) {
  try {
    const store = yield select();
    const catMap = store.basic_filtering.catMapping;
    const { activeDirectory } = store.account;
    const [result, locations, cats, ad] = yield all([
      call(Api.getData, {
        ...action.params,
        order: store.wfLogs.sort.direction,
        range: store.wfLogs.range,
        account_id: store.account.selected,
        page: 'wf_logs',
      }),
      call(Api.locationPolicy.read, {}),
      call(catMap.loaded ? () => {} : Api.getData, {
        page: 'get_basic_policies',
      }),
      call(activeDirectory.length ? () => {} : Api.directory.read, {
        account_id: store.account.selected,
      }),
    ]);

    const locationMap = locations.reduce(
      (obj, location) => ({
        ...obj,
        ...location.locations.reduce(
          (o, loc) => ({
            [loc.id]: loc.name,
          }),
          {}
        ),
      }),
      {}
    );

    yield all([
      put(Types.storeLocations(locationMap)),
      ...(!catMap.loaded
        ? [
            put(
              storeLoadedCats(
                cats.categories,
                cats.supercategories,
                cats.catsv2,
                cats.catsv3,
                cats.wcs_version
              )
            ),
          ]
        : []),
      ...(!activeDirectory.length ? [put(getActiveDirectorySuccess(ad))] : []),
    ]);

    yield put(Types.getSuccess(result));
  } catch (e) {
    yield put(Types.getFailure(e));
  }
}

export function* wfLogsReducerFlow() {
  yield takeEvery(Types.WF_LOGS_INIT_DATA, initData);
  yield takeEvery(Types.WF_LOGS_GET_DATA, fetchLogs);
  yield takeEvery(Types.WF_LOGS_UPDATE_RANGE, fetchLogs);
  yield takeEvery(Types.WF_LOGS_UPDATE_SORT, fetchLogs);
  yield takeEvery(Types.WF_LOGS_GET_MORE_LOGS, fetchMoreLogs);
  yield takeEvery(Types.WF_LOGS_CHANGE_PAGE, changePage);
  yield takeEvery(Types.WF_LOGS_EXPORT_CSV, exportCSV);
  yield takeEvery(Types.WF_LOGS_QUERY_DATA, fetchLogs);
}
