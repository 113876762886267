import { createSelector } from 'reselect';
import { reportTypes } from '../actions/reports';

const getSubscriptions = state => state.account.subscriptions;
const getTypes = () => reportTypes;
const getActiveDirectory = state => state.account.activeDirectory;

const getFilteredTypes = createSelector(
  [getTypes, getSubscriptions],
  (options, subscriptions) => {
    const groupedOptions = [];

    options.forEach(option => {
      if (option.type === 'label') {
        groupedOptions[groupedOptions.length] = [option];
      } else if (option.subscriptions.some(sub => subscriptions[sub])) {
        groupedOptions[groupedOptions.length - 1].push(option);
      }
    });
    return [].concat(...groupedOptions.filter(group => group.length > 1));
  }
);

const getUserOptions = createSelector(
  [getActiveDirectory],
  ad => {
    const filterAD = ad.filter(entry => entry.type === 'user');
    return filterAD.map(entry => ({
      type: entry.type,
      label: entry.display_name,
      key: entry.uuid,
      value: entry.uuid,
    }));
  }
);

export { getFilteredTypes, getUserOptions };
