import * as Types from '../actions/audit_log';
import * as BasicFilterTypes from '../actions/basic_filtering';
import { call, select, put, takeEvery } from 'redux-saga/effects';
import Api from './Api';

export default (
  state = {
    logs: [],
    logCount: 0,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case Types.AUDIT_LOG_FETCH_DATA:
    case Types.AUDIT_LOG_INIT_DATA:
      return {
        ...state,
        loading: true,
      };
    case Types.AUDIT_LOG_SORT_DATA:
      const { orderBy, order, specialMap } = action;
      const sortedLogs =
        order === 'desc'
          ? state.logs.slice().sort(function(a, b) {
              a = specialMap
                ? specialMap.get(a['_source'][orderBy])
                : a['_source'][orderBy];
              b = specialMap
                ? specialMap.get(b['_source'][orderBy])
                : b['_source'][orderBy];

              return b < a ? -1 : 1;
            })
          : state.logs.slice().sort(function(a, b) {
              a = specialMap
                ? specialMap.get(a['_source'][orderBy])
                : a['_source'][orderBy];
              b = specialMap
                ? specialMap.get(b['_source'][orderBy])
                : b['_source'][orderBy];

              return a < b ? -1 : 1;
            });

      return {
        ...state,
        logs: sortedLogs,
      };
    case Types.GET_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        logs: action.result.hits || [],
        logCount: action.result.total,
        loading: false,
      };
    default:
      return state;
  }
};

function* fetchLogs(action) {
  try {
    const store = yield select();
    const catMap = store.basic_filtering.catMapping;
    let cat = {};
    if (!catMap.loaded) {
      const catsResult = yield call(Api.getData, {
        page: 'get_basic_policies',
      });
      cat = {
        categories: catsResult['categories'],
        supercategories: catsResult['supercategories'],
      };
      yield put(
        BasicFilterTypes.storeLoadedCats(
          cat['categories'],
          cat['supercategories'],
          catsResult['catsv2'],
          catsResult['catsv3'],
          catsResult['wcs_version']
        )
      );
    } else {
      cat = {
        categories: catMap['categories'],
        supercategories: catMap['supercategories'],
      };
    }

    let params = {
      ...action.params,
      page: 'audit',
    };
    if (action.smb) {
      params.accounts = store.account.selected;
      params.smb = true;
    }
    const result = yield call(Api.getData, params);
    yield put(Types.getAuditLogSuccess(result));
  } catch (e) {
    yield put(Types.getAuditLogFailure(e));
  }
}

export function* auditLogReducerFlow() {
  yield takeEvery(Types.AUDIT_LOG_INIT_DATA, fetchLogs);
  yield takeEvery(Types.AUDIT_LOG_FETCH_DATA, fetchLogs);
}
