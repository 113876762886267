import { connect } from 'react-redux';
import StatusBar from '../../components/StatusBar';

const mapStateToProps = state => ({
  saving: state.activate.processing,
  show: state.activate.processing || Boolean(state.activate.error),
  messageLid: state.activate.message,
  result: state.activate.result,
});

export default connect(
  mapStateToProps,
  undefined
)(StatusBar);
