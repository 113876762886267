import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import IconButton from '@material-ui/core/IconButton';

import { ExpandIcon, CollapseIcon, PersonIcon, PeopleIcon } from '../icons';

import GroupMenu from '../../containers/user_authentication/GroupMenu';
import UserMenu from '../../containers/user_authentication/UserMenu';

import Timestamp from '../Timestamp';

const GroupRow = ({
  classes,
  group,
  index,
  handleToggle,
  expanded,
  ...other
}) => (
  <React.Fragment>
    <TableRow data-test-id={`group-row-${index}`} {...other}>
      <TableCell className={classes.expandCell}>
        <IconButton
          data-test-id="expand-row"
          className={classes.expandButton}
          aria-label="Toggle show group users"
          onClick={e => handleToggle(group.id)}
          disableRipple
        >
          {expanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>
      </TableCell>
      <TableCell
        colSpan={4}
        className={classes.labelCell}
        style={{ paddingLeft: 0 }}
      >
        <div className={classes.label}>
          <PeopleIcon />
          <div className={classes.groupName}>
            {`${group.name} (${group.users.length})`}
          </div>
        </div>
      </TableCell>
      <TableCell className={classes.moreCell}>
        <GroupMenu
          group={{
            id: group.id,
            name: group.name,
          }}
        />
      </TableCell>
    </TableRow>
    {expanded &&
      group.users.map(user => (
        <TableRow className={classes.userRow} key={`${group.name}-${user.id}`}>
          <TableCell />
          <TableCell className={classes.labelCell}>
            <div className={classes.label}>
              <PersonIcon />
              <div className={classes.email}>{user.name}</div>
            </div>
          </TableCell>
          <TableCell>
            <div className={classes.email}>{user.email}</div>
          </TableCell>
          <TableCell>{user.src_ip || '-'}</TableCell>
          <TableCell>
            {user.timestamp ? <Timestamp timestamp={user.timestamp} /> : '-'}
          </TableCell>
          <TableCell className={classes.moreCell}>
            <UserMenu user={user} group={group} />
          </TableCell>
        </TableRow>
      ))}
  </React.Fragment>
);

const styles = {
  moreCell: {
    width: '24px',
    paddingRight: '12px',
  },
  email: {
    maxWidth: '275px',
    wordWrap: 'break-word',
  },
  labelCell: {
    '& svg': {
      color: '#5F5F5F',
      marginRight: '8px',
      height: '16px',
    },
  },
  userRow: {
    '& $labelCell': {
      paddingLeft: '28px',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  groupName: {
    maxWidth: '1000px',
    wordWrap: 'break-word',
  },
  expandButton: {
    padding: 0,
    '& svg': {
      height: 24,
      width: 24,
      cursor: 'pointer',
    },
  },
};

export default withStyles(styles, { name: 'GroupRow' })(GroupRow);
