import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Translate, I18n } from 'react-i18nify';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import NetworkNameBar from '../../containers/basic_filtering/NetworkNameBar';

import { Mixpanel } from '../../lib/Mixpanel';

const GettingStarted = ({ classes, type, updateIPType }) => (
  <Grid
    id="network-ip-type"
    container
    spacing={0}
    className={classes.container}
  >
    <Grid item xs={12} className={classes.levelContainer}>
      <NetworkNameBar />
    </Grid>
    <Grid item xs={12} className={classes.addContainer}>
      <FormControl
        component="fieldset"
        required
        className={classes.formControl}
      >
        <RadioGroup
          aria-label="IPType"
          name="ipType"
          className={classes.group}
          value={type}
          onChange={event => updateIPType(event.target.value)}
        >
          <FormControlLabel
            onClick={() => {
              Mixpanel.track(
                'DNS Filtering / Add Location Dialog / Getting Started-Automatic Option',
                {}
              );
            }}
            classes={{ label: classes.labelText }}
            value="dynamic"
            control={<Radio color="primary" />}
            label={I18n.t('components.basicFiltering.dynamicIPLabel')}
          />
          <Translate
            value="components.basicFiltering.dynamicIPSub"
            tag="div"
            className={classes.subtext}
          />
          <FormControlLabel
            onClick={() => {
              Mixpanel.track(
                'DNS Filtering / Add Location Dialog / Getting Started-Manual Option',
                {}
              );
            }}
            classes={{ label: classes.labelText }}
            value="static"
            control={<Radio color="primary" />}
            label={I18n.t('components.basicFiltering.staticIPLabel')}
          />
          <Translate
            value="components.basicFiltering.staticIPSub"
            tag="div"
            className={classes.subtext}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  </Grid>
);

GettingStarted.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  updateIPType: PropTypes.func.isRequired,
};

const styles = {
  container: {
    paddingBottom: '40px',
  },
  addContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
  labelText: {
    fontSize: '16px',
  },
  subtext: {
    paddingLeft: '30px',
    fontSize: '14px',
    lineHeight: '24px',
  },
};

export default withStyles(styles, { name: 'GettingStarted' })(GettingStarted);
