export const JWE_SESSION_STORE = 'Endpoint_shield_session';
export const SSO_SESSION_STORE = 'BNES_appsec_session';
export const SSO_REFRESH_TOKEN = 'BNES_appsec_refresh';
export const SSO_BCC_TOKEN = 'CLOUD_AT';
export const SSO_SUPPORT_INFO = 'BCS_support_info';
export const BCS_REFRESH_STORE = 'BCS_REFRESH';
export let BCS_HOST = {
  'us-east-1': 'dev.bcs.aws.cudaops.com',
  'eu-west-1': 'www.dev.ie.bcs.aws.cudaops.com',
};
export let PRIMARY_DNS = '35.174.51.19';
export let SECONDARY_DNS = '35.174.51.19';
export let COOKIE_DOMAIN = '.bcs.aws.cudaops.com';
export const RELEASE_NOTES_URL =
  'https://campus.barracuda.com/product/ContentShield/doc/85493754/release-notes';
let ENV_STAGE = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE
  : process.env.PUBLIC_URL;
// Get the stage at the end of the url
if (ENV_STAGE) {
  let subpath = ENV_STAGE.split('/');
  ENV_STAGE = subpath[subpath.length - 1];
}
let DDNS_DOWNLOAD =
  'https://s3.amazonaws.com/bcs-files-dev/ddns/Dynamic%20IP%20Updater.msi';
let AGENT_DOWNLOAD =
  'https://s3.amazonaws.com/bcs-files-dev/ddns/Dynamic%20IP%20Updater.msi';
let LOGO_PATH = '/default-logo.svg';

if (process.env.REACT_APP_ENV === 'qa') {
  BCS_HOST = {
    'us-east-1': 'qa.bcs.aws.cudaops.com',
    'eu-west-1': 'qa.ie.bcsaws.cudaops.com',
  };
  DDNS_DOWNLOAD =
    'https://s3.amazonaws.com/bcs-files-qa/ddns/Dynamic%20IP%20Updater.msi';
  COOKIE_DOMAIN = '.bcs.aws.cudaops.com';
  PRIMARY_DNS = '34.233.212.145';
  SECONDARY_DNS = '34.229.35.195';
} else if (process.env.REACT_APP_ENV === 'eu-west-1-qa') {
  BCS_HOST = {
    'us-east-1': 'qa.ie.bcs.aws.cudaops.com',
    'eu-west-1': 'qa.ie.bcs.aws.cudaops.com',
  };
  DDNS_DOWNLOAD =
    'https://s3.amazonaws.com/bcs-files-qa/ddns/Dynamic%20IP%20Updater.msi';
  COOKIE_DOMAIN = '.bcs.aws.cudaops.com';
  PRIMARY_DNS = '34.233.212.145';
  SECONDARY_DNS = '34.229.35.195';
} else if (process.env.REACT_APP_ENV === 'staging') {
  BCS_HOST = {
    'us-east-1': 'staging.bcs.aws.cudaops.com',
    'eu-west-1': 'staging.ie.bcs.aws.cudaops.com',
  };
  DDNS_DOWNLOAD =
    'https://s3.amazonaws.com/bcs-files-staging/ddns/Dynamic%20IP%20Updater.msi';
  COOKIE_DOMAIN = '.bcs.aws.cudaops.com';
  PRIMARY_DNS = '18.210.81.209';
  SECONDARY_DNS = '18.210.126.49';
} else if (
  process.env.REACT_APP_ENV === 'production' ||
  (ENV_STAGE === '' && process.env.NODE_ENV === 'production')
) {
  BCS_HOST = {
    'us-east-1': 'bcs.barracudanetworks.com',
    'eu-west-1': 'bcs.ie.barracudanetworks.com',
  };
  DDNS_DOWNLOAD =
    'https://s3.amazonaws.com/bcs-files-production/ddns/Dynamic%20IP%20Updater.msi';
  COOKIE_DOMAIN = '.barracudanetworks.com';
  PRIMARY_DNS = '34.203.72.42';
  SECONDARY_DNS = '34.203.170.29';
} else if (process.env.REACT_APP_ENV === 'eu-central-1-staging') {
  BCS_HOST = {
    'us-east-1': 'staging.bcs.aws.cudaops.com',
    'eu-west-1': 'staging.ie.bcs.aws.cudaops.com',
  };
  DDNS_DOWNLOAD =
    'https://bcs-files-staging.s3.amazonaws.com/ddns/Dynamic+IP+Updater.msi';
  PRIMARY_DNS = '3.121.90.111';
  SECONDARY_DNS = '3.122.19.70';
} else if (process.env.REACT_APP_ENV === 'eu-central-1-production') {
  BCS_HOST = {
    'us-east-1': 'bcs.barracudanetworks.com',
    'eu-west-1': 'bcs.ie.barracudanetworks.com',
  };
  DDNS_DOWNLOAD =
    'https://bcs-files-staging.s3.amazonaws.com/ddns/Dynamic+IP+Updater.msi';
  PRIMARY_DNS = '3.121.90.111';
  SECONDARY_DNS = '3.122.19.70';
} else if (process.env.REACT_APP_ENV === 'eu-west-1-staging') {
  BCS_HOST = {
    'us-east-1': 'staging.bcs.aws.cudaops.com',
    'eu-west-1': 'staging.ie.bcs.aws.cudaops.com',
  };
  DDNS_DOWNLOAD =
    'https://eu-west-1-bcs-files-staging.s3-eu-west-1.amazonaws.com/ddns/Dynamic+IP+Updater.msi';
  PRIMARY_DNS = '63.34.36.112';
  SECONDARY_DNS = '52.208.140.63';
} else if (process.env.REACT_APP_ENV === 'eu-west-1-production') {
  BCS_HOST = {
    'us-east-1': 'bcs.barracudanetworks.com',
    'eu-west-1': 'bcs.ie.barracudanetworks.com',
  };
  DDNS_DOWNLOAD =
    'https://eu-west-1-bcs-files-production.s3-eu-west-1.amazonaws.com/ddns/Dynamic+IP+Updater.msi';
  PRIMARY_DNS = '34.254.37.26';
  SECONDARY_DNS = '34.252.77.74';
}

export const STAGE =
  ENV_STAGE || (ENV_STAGE === '' && process.env.NODE_ENV === 'production')
    ? ENV_STAGE
    : 'v1';
const API_ENDPOINT =
  process.env.REACT_APP_DOMAIN && process.env.REACT_APP_DOMAIN !== ''
    ? process.env.REACT_APP_DOMAIN
    : 'https://api.dev.bcs.aws.cudaops.com';
export const BASE_URL = STAGE
  ? API_ENDPOINT + '/portal/' + STAGE
  : API_ENDPOINT + '/portal';
export const DOWNLOAD_URL = STAGE
  ? API_ENDPOINT + '/download/' + STAGE
  : API_ENDPOINT + '/download';
export const TOKEN_VALIDATOR_URL = BASE_URL + '/token_validator';
export const COOKIE_VALIDATOR_URL = BASE_URL + '/cookie_validator';
export const CSV_URL = DOWNLOAD_URL + '/csv';
export const PDF_URL = DOWNLOAD_URL + '/pdf';
export const REPORT_PDF_URL = DOWNLOAD_URL + '/pdf_report';
export const LOGIN_URL = BASE_URL + '/login';
export const TOKEN_URL = BASE_URL + '/token';
export const ACCOUNT = 'EPS_account';
export const ACCOUNT_NAME = 'EPS_account_name';
export const EPS_AUTHDB_SERVICE_ID = 17;
export { DDNS_DOWNLOAD };
export { AGENT_DOWNLOAD };
export { LOGO_PATH };

export const WCS_30_COLS = [
  [
    'Adult Material',
    'Security',
    'Illegal or Improper',
    'Violence and Weapons',
    'Social Media',
    'Technology',
  ],
  [
    'Business',
    'Education',
    'Online Communication',
    'Society and Lifestyle',
    'Adult Recreation or Illegal',
    'Travel',
  ],
  [
    'Commerce and Shopping',
    'Entertainment',
    'News and Information',
    'Cultural',
    'Health and Medicine',
    'Other',
  ],
];

export const regions = [
  { label: 'United States', value: 'us-east-1' },
  { label: 'Europe', value: 'eu-west-1' },
];

export const AD_CAMPUS_URL =
  'https://campus.barracuda.com/product/ContentShield/doc/79464883/ldap-active-directory-and-barracuda-content-shield/';
