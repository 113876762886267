import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import LaunchIcon from '@material-ui/icons/Launch';
import { DownloadIcon, GreyWindowsIcon, GreyMacIcon } from '../icons';

import { RELEASE_NOTES_URL } from '../../lib/Env';

const InstallerRows = ({ classes, installers, dataTestId, ...other }) =>
  installers.map((n, i) => (
    <TableRow
      className={classes.bodyRow}
      key={`${n.os}${n.version}`}
      {...other}
      data-test-id={`row-${dataTestId}-${i}`}
    >
      <TableCell className={classes.expandCell} />
      <TableCell data-test-id={`row-${dataTestId}-name-${i}`}>
        {(() =>
          ({
            windows: (
              <span className={classes.osIcon}>
                <GreyWindowsIcon />
                Windows Agent
              </span>
            ),
            Macintosh: (
              <span className={classes.osIcon}>
                <GreyMacIcon />
                Mac OS Agent
              </span>
            ),
          }[n.os]))()}
      </TableCell>
      <TableCell data-test-id={`row-${dataTestId}-version-${i}`}>{`v${
        n.version
      }`}</TableCell>
      <TableCell data-test-id={`row-${dataTestId}-version-${i}`}>
        <Button
          data-test-id="agent-release-notes"
          color="primary"
          type="text"
          className={classes.releaseNotesButton}
          onClick={() => window.open(RELEASE_NOTES_URL, '_blank')}
        >
          <Translate value="components.deploy.releaseNotes" />
          <LaunchIcon className={classes.icon} />
        </Button>
      </TableCell>
      <TableCell data-test-id={`row-${dataTestId}-action-${i}`}>
        <Button
          data-test-id="agent-download"
          color="primary"
          type="text"
          onClick={() => window.open(n.download_link, '_blank')}
        >
          <DownloadIcon className={classes.icon} color="action" />
          <Translate value="shared.download" />
        </Button>
      </TableCell>
    </TableRow>
  ));

const styles = theme => ({
  bodyRow: {
    height: '40px',
    '& > td': {
      fontSize: '14px',
      padding: '4px',
      '&:first-child': {
        paddingLeft: '24px',
        paddingRight: '0px',
      },
      '&:last-child': {
        paddingRight: '24px',
        textAlign: 'right',
      },
    },
  },
  expandCell: {
    width: 24,
  },
  osIcon: {
    '& > svg': {
      color: theme.colors.greyText,
      height: '25px',
      width: '25px',
      marginRight: '5px',
    },
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
  },
  downloadButton: {
    padding: '5px 16px',
    fontWeight: 'normal',
  },

  icon: {
    marginRight: '8px',
    marginLeft: '8px',
    height: 18,
  },
});

InstallerRows.propTypes = {
  installers: PropTypes.array.isRequired,
  dataTestId: PropTypes.string,
};

export default withStyles(styles, { name: 'InstallerRows' })(InstallerRows);
