import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Tooltip from '@material-ui/core/Tooltip';

import PaginatorComponent from '../Paginator';
import CudaTableTitle from '../CudaTableTitle';
import Timestamp from '../../containers/Timestamp';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    margin: 0,
  },
  dateColumn: {
    width: '20%',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  pathColumn: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileColumn: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tableTitle: {
    '& > th': {
      borderTop: '1px solid #DDDDDD',
    },
  },
  reportTableBody: {
    '& > tr': {
      height: '40px',
    },
  },
});

class TableComponent extends Component {
  state = {
    anchorEl: null,
    open: false,
    moreAnchorEl: null,
    icons: ['desc', 'none', 'none', 'none', 'none'],
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRequestClose = option => {
    this.setState({ open: false });
    this.props.updateRows(option);
  };

  render() {
    const {
      classes,
      entries,
      total,
      filters,
      firstPage,
      nextPage,
      prevPage,
      lastPage,
    } = this.props;

    const options = [10, 20, 30];

    return (
      <div className={classes.root}>
        <div>
          <PaginatorComponent
            grayBackground={true}
            text="Rows per page: "
            itemsTotal={total}
            pageNumber={filters.index}
            onPaginateClick={this.handleClick}
            onFirstClick={firstPage}
            onNextClick={nextPage}
            onPreviousClick={prevPage}
            onLastClick={lastPage}
            itemsPerPage={filters.rows}
          />
          <Menu
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onRequestClose={this.handleClose}
          >
            {options.map(option => (
              <MenuItem
                key={option}
                onClick={() => {
                  this.handleRequestClose(option);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <Table>
          <TableHead>
            <TableRow className={classes.tableTitle}>
              <TableCell>
                <CudaTableTitle value="shared.date" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.user" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.endpointName" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.filePath" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.fileName" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.fileType" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.reportTableBody}>
            {entries.map((n, i) => {
              return (
                <TableRow key={i}>
                  <TableCell className={classes.dateColumn}>
                    <Timestamp timestamp={n['_source'].timestamp} />
                  </TableCell>
                  <TableCell>{n['_source']['user']}</TableCell>
                  <TableCell>
                    {n['_source']['beat']
                      ? n['_source']['beat']['hostname']
                      : n['_source']['agent']
                      ? n['_source']['agent']['hostname']
                      : ''}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      id="tooltip-top-start"
                      title={n['_source']['scan_path'].replace(/[^\\/]*$/, '')}
                      placement="top-start"
                    >
                      <div className={classes.pathColumn}>
                        <span>
                          {n['_source']['scan_path'].replace(/[^\\/]*$/, '')}
                        </span>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.fileColumn}>
                    <Tooltip
                      id="tooltip-top-start"
                      title={n['_source']['scan_path'].replace(/^.*[\\/]/, '')}
                      placement="top-start"
                    >
                      <div className={classes.fileColumn}>
                        {n['_source']['scan_path'].replace(/^.*[\\/]/, '')}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{n['_source']['scan_file_type']}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div>
          <PaginatorComponent
            grayBackground={true}
            text="Rows per page: "
            itemsTotal={total}
            pageNumber={filters.index}
            onPaginateClick={this.handleClick}
            onFirstClick={firstPage}
            onNextClick={nextPage}
            onPreviousClick={prevPage}
            onLastClick={lastPage}
            itemsPerPage={filters.rows}
          />
          <Menu
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onRequestClose={this.handleClose}
          >
            {options.map(option => (
              <MenuItem
                key={option}
                onClick={() => {
                  this.handleRequestClose(option);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    );
  }
}

TableComponent.propTypes = {
  entries: PropTypes.array.isRequired,
};

export default withStyles(styles, { name: 'ReportsTableComponent' })(
  TableComponent
);
