import { connect } from 'react-redux';
import ExistingUserSelectAccount from '../../components/signup/ExistingUserSelectAccount';
import { deleteSession } from '../../actions/account';
import {
  storeAccount,
  setLogin,
  clearSession,
  updateFormFields,
} from '../../actions/signup';
import Auth from '../../lib/Auth';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  accounts: state.signup.accounts,
  msp: state.signup.msp,
  email: (state.signup.sso && state.signup.sso.email) || '',
  processing: state.activate.processing,
  region: state.signup.userForm.region,
});

const mapDispatchToProps = dispatch => ({
  deleteSession: token => {
    Mixpanel.track('Trial / Select Account-Sign Out', {});
    Auth.logout();
    dispatch(deleteSession(token));
    dispatch(setLogin(false));
    dispatch(clearSession());
  },
  saveInfo: accountId => {
    Mixpanel.track('Trial / Select Account-Submit', {});
    dispatch(storeAccount(accountId));
  },
  updateField: event => {
    dispatch(
      updateFormFields('userForm', { [event.target.name]: event.target.value })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingUserSelectAccount);
